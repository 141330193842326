import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { selectAuthState } from '@ea/auth';
import { BaseComponent } from '@ea/components';
import { AddressService } from '@ea/services';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { AddressComponent } from '../address-component/address.component';
import { FormsModule } from '@angular/forms';
import { IResponseAddress } from '@ea/models';
import environment from '../../../../../environments/environment';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'ea-checkout-addresses',
  standalone: true,
  imports: [CommonModule, AddressComponent, FormsModule,RouterLink],
  templateUrl: './checkout-addresses.component.html',
  styleUrls: ['./checkout-addresses.component.scss'],
})
export class CheckoutAddressesComponent extends BaseComponent {
  addresses: any[] = [];
  selectedAddress?: IResponseAddress;
  @Output() changes = new EventEmitter(true);

  public get availableAddresses() {
    return this.addresses;
  }

  checkCountryAvailable(address: any) {
    if (environment.base === '') {
      return false; //enable all addresses for dev and local envs
    }

    const lowerCaseCountry = address.country.toLowerCase();
    switch (lowerCaseCountry) {
      case 'united kingdom':
      case 'gb':
        return environment.base !== 'uk'; //to disable not uk address for /uk envs
        case 'gbp':
          return environment.base !== 'uk';
      case 'gbr':
        return environment.base !== 'uk';
      case 'usa':
        return environment.base !== 'us'; //to disable not usa address for /us envs

      default:
        return true;
    }
  }

  onAddressSelected(address: any) {
    if(address == "new address"){
      this.router.navigate(['/account/addresses/new-address']);
    }
    if (!this.checkCountryAvailable(address)) {
      this.selectedAddress = address;
    }
    this.changes.emit(address);
  }

  constructor(private addressService: AddressService, private store: Store, private router : Router) {
    super();

    this.subscriptions.push(
      this.store
        .select(selectAuthState)
        .pipe(
          switchMap((state) =>
            this.addressService.getAddress(state.user.customId)
          )
        )
        .subscribe((addresses) => {
          this.addresses = addresses;
          this.onAddressSelected(
            this.addresses.find((x) => x.isDefault) ?? this.addresses[0]
          );
        })
    );
  }
}
