<div class="container">
    <div class="row">
        <div class="col-lg-8 my-5">
            <ea-checkout-addresses
                    (changes)="onAddressChanged($event)"
            ></ea-checkout-addresses>
            <ea-select-delivery-options
                    *ngIf="selectedAddress"
                    [address]="selectedAddress"
                    [itemsByVendor]="itemsByVendor"
                    [cart]="cart$ | async"
            >
            </ea-select-delivery-options>
        </div>
        <div class="col-lg-4 my-5 justify-content-end">
            <div class="subtotal-content" [ngClass]="currentFontSize">
                <div
                        class="card font-title bg-theme-surface focus-outline text-theme-on-surface"
                >
                    <ng-container *ngIf="cart$ | async as cart">
                        <div class="row">
                            <div class="col-lg-8">
                                <h2 class="font-bold">Subtotal</h2>
                            </div>

                            <div class="col-lg-4">
                                <div class="float-right mb-3 text-right"
                                     *ngIf="estimatedShippingAmount -  this.additionvalues  <= 0">
                                    <p class="text-theme-on-surface">
                                        {{ cart.subTotal + this.additionvalues | currency : cart.currency }}
                                    </p>
                                </div>
                                <div class="float-right mb-3 text-right"
                                     *ngIf="estimatedShippingAmount -  this.additionvalues  > 0">
                                    <p class="text-theme-on-surface">
                                        {{ cart.subTotal - estimatedShippingAmount  | currency : cart.currency }}
                                    </p>
                                </div>
                            </div>
                            <hr class="text-light-gray"/>
                        </div>
                        <div class="row pt-3">
                            <div class="col-lg-8">
                                <strong>Estimated Shipping</strong>
                            </div>

                            <div class="col-lg-4" *ngIf="estimatedShippingAmount -  this.additionvalues  <= 0">
                                <div class="float-right mb-3 text-right">
                                    {{ 0 | currency : cart.currency }}
                                </div>
                            </div>
                            <div class="col-lg-4" *ngIf="estimatedShippingAmount -  this.additionvalues  > 0">
                                <div class="float-right mb-3 text-right">
                                    {{ estimatedShippingAmount - this.additionvalues | currency : cart.currency }}
                                </div>
                            </div>
                        </div>
                        <div class="row pt-3" *ngIf=" cart.totalDiscount > 0">

                            <div class="col-lg-8">
                                <strong>Discount</strong>
                            </div>

                            <div class="col-lg-4">
                                <div class="float-right  text-right">
                                    {{ cart.totalDiscount | currency : cart.currency }}
                                </div>

                            </div>
                            <div class=" mb-3" *ngIf="cart.allPromosApplied && cart.allPromosApplied.length > 0">
                                <div *ngFor="let promo of cart.allPromosApplied">
                                    <button
                                            *ngIf="promo.promoCode && promo.promoCode != ''"
                                            [disabled]="isLoading$ | async"
                                            (click)="removePromoCode(promo.promoCode)"
                                            title="Remove {{promo.promoCode}} Promo Code"
                                            style="font-size: 12px; color: brown; "
                                            class="ea-button mt-2 uppercase font-bold text-decoration-underline cart-item-font text-right"
                                    >
                                        Remove {{promo.promoCode}}
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-8">
                                <h2>Total</h2>
                            </div>
                            <div class="col-lg-4">
                                <div class="float-right mb-3 text-right">
                                    <p class="text-theme-on-surface"
                                       *ngIf="estimatedShippingAmount -  this.additionvalues  <= 0">
                                        {{ cart.totalAmount + this.additionvalues   | currency : cart.currency }}
                                    </p>
                                    <p class="text-theme-on-surface"
                                       *ngIf="estimatedShippingAmount -  this.additionvalues  > 0">
                                        {{ cart.totalAmount    | currency : cart.currency }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-3">
                            <div class="col-lg-12">
                                <button type="button" class="layout font-bold" (keydown)="show()" (click)="show()">
                                    <mat-icon *ngIf="!showlayout">play_arrow</mat-icon>
                                    <mat-icon *ngIf="showlayout">arrow_drop_down</mat-icon>
                                    Apply Promo Code
                                </button>

                                <div *ngIf="showlayout">
                                    <div class="col-lg-12">
                                        <div class="ea-form-field mt-2" style="width: 100%;">
                                            <input
                                                    type="text"
                                                    id="prmocode"
                                                    name="prmocode"
                                                    [(ngModel)]="prmocode"
                                                    style="width: 100%;"
                                                    class="ea-input"
                                                    alt="promo code input"
                                            />
                                        </div>

                                    </div>
                                    <button (click)="applyPromoCode()"
                                            [disabled]="!prmocode || prmocode ==''"
                                            class="ea-button ea-button-secondary focus-ring font-title mt-3"
                                            style="width: 100%;"
                                    >Apply promo code
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <button
                                    (click)="toggleDisable(true)"
                                    [routerLink]="['/payment']"
                                    [disabled]="isCartInvalidToCheckout$ | async"
                                    title="Continue"
                                    class="ea-button ea-button-primary text-uppercase w-100 p-1 font-title"
                                    aria-label="Continue to payment"
                            >
                                Continue
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
