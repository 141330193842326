import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared';
import { LocalStorageService, ToastService } from '@ea/services';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule } from '@angular/forms';
import { FormFieldComponent } from '../../../../components/form-field/form-field.component';
import { MatIconModule } from '@angular/material/icon';
import { CharityService } from 'src/app/services/shared/charityService/charity.service';
import { CharityData } from 'src/app/models/CharityData ';

@Component({
  selector: 'app-charity-page',
  standalone: true,
  templateUrl: './charity-page.component.html',
  styleUrls: ['./charity-page.component.scss'],
  imports: [
    CommonModule,
    RouterLink,
    NgFor,
    NgIf,
    SharedModule,
    NgxSkeletonLoaderModule,
    FormFieldComponent,
    MatIconModule,
    FormsModule,
  ],
})
export class CharityPageComponent implements OnInit {
  loader: boolean = true;
  selected: CharityData | undefined;
  options: CharityData[] = [];
  constructor(
    private charityService: CharityService,
    private storage: LocalStorageService,
    private toasterService: ToastService
  ) {}

  ngOnInit() {
    this.charityService
      .getProfileDropDown(this.storage.getItem('customId'))
      .subscribe((x) => {
        this.options = x;
        this.selected = x.filter((s) => s.isDefault)[0];
        this.loader = false;
      });
  }
  onOptionsChanged(event: CharityData) {
    this.loader = true;
    this.charityService
      .insertDefaultCharity(this.storage.getItem('customId'), event.charityId)
      .subscribe((x) => {
        this.toasterService.show(
          'Your default Charity now is ' + event.charityName
        );
        this.loader = false;
      });
  }
}
