<div *ngIf="!this.isBusy && skus.length > 0">
  <ea-products [isLikePages]="true" [productslikes]="skus"></ea-products>
</div>
<div *ngIf="skus.length == 0">
  <section>
    <div class="container">
      <div class="my-products pt-3 pb-5">
        <div class="row">
  <ng-container *ngIf="skus.length == 0">
    <p>You have no favorite product</p>
  </ng-container>
  </div>
  </div>
</div>