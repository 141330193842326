<div *ngIf="!isCartLoaded$.value" class="container">
  <div class="row pt-5">
    <div class="row ml-2 pt-1">
      <div class="col-4 col-sm-4 col-md-3 m-1">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'height.px': 40,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row ml-2 pt-1">
      <div class="col-5 col-sm-5 col-md-4 m-1">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row p-8">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 60,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row p-8">
      <div class="col-md-3">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'height.px': 50,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-md-3">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'height.px': 50,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-md-3">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'height.px': 50,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-md-3">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'height.px': 50,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-10 col-sm-10 col-md-9 m-1">
        <ngx-skeleton-loader
          count="1"
          [theme]="{
            'height.px': 50,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>

<section class="pt-5">
  <div class="container" *ngIf="this.isCartLoaded$ | async">
    <div class="row justify-content-center" *ngIf="!orderId; else thankYou">
      <div class="header mt-5">
        <h1 class="font-bold font-title">Payment</h1>
        <h2>Please complete your payment below.</h2>
      </div>

      <div class="col-lg-12">
        <div class="pt-3 mb-5">
          <form
            [formGroup]="form"
            class="bg-theme-surface"
            (ngSubmit)="makePayment()"
          >
            <section class="totals" *ngIf="cart && cart.cartId">
              <div class="row">
                <div class="col mb-4">
                  <h3>Order Total:</h3>
                </div>
                <div class="col mb-4">
                  <div class="float-right">
                    <h3>{{ totalAmount | currency : cart.currency }}</h3>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <h3>VAT Included:</h3>
                </div>
                <div class="col">
                  <div class="float-right">
                    <h3>{{ totalTax | currency : cart.currency }}</h3>
                  </div>
                </div>
              </div>
              <hr class="text-light-gray" />
            </section>
            <legend>
              <h3 class="mt-2 mb-3">Billing Address</h3>
            </legend>

            <div class="row">
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="First Name"
                name="firstName"
                alt="First Name input"
                [form]="form"
              ></ea-form-field>
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="Last Name"
                name="lastName"
                alt="Last Name input"
                [form]="form"
              ></ea-form-field>
            </div>

            <div class="row">
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="Address Line 1"
                name="addressLine1"
                alt="Address Line 1 input"

                [form]="form"
              ></ea-form-field>
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="Address Line 2"
                name="addressLine2"
                alt="Address Line 2 input"
                [form]="form"
              ></ea-form-field>
            </div>

            <div class="row">
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="Town / City"
                name="city"
                [form]="form"
                alt="city input"

              ></ea-form-field>
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="Country"
                name="country"
                [form]="form"
                alt="country input"

              ></ea-form-field>
            </div>

            <div class="row">
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="Postcode"
                name="postalCode"
                alt="postcode input"

                [form]="form"
              ></ea-form-field>
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="County"
                name="county"
                alt="county input"
                [form]="form"
              ></ea-form-field>
            </div>
            <div class="row">
              <ea-form-field
                class="col-sm-12 col-md-6"
                label="Phone Number"
                name="phoneNumber"
                [form]="form"
                alt="phone number input"
              ></ea-form-field>
       
            </div>
            <br />
            <div
              class="rowm m-2"
              *ngIf="this.isLoaded && cards && thereAreCards && !displayNewCard"
            >
              <h2 class="mb-2">List cards</h2>

              <div
                class="col-lg-12 col-md-12 selects"
                (click)="showList()"
                [ngClass]="{ highlight: ShowCardList }"
              >
                <div class="row m-2" *ngIf="selectedCard == ''">
                  <div class="col-11 d-flex align-items-center">
                    Choose card
                  </div>
                  <div class="col-1 d-flex align-items-center">
                    <mat-icon [ngClass]="{ arrow: ShowCardList }">
                      keyboard_arrow_left
                    </mat-icon>
                  </div>
                </div>
                <div class="row align-items-center" *ngIf="selectedCard != ''">
                  <div class="col-1 m-2">
                    <img
                      data-src="assets/images/cardsIcons/{{
                        selectedFullCard.type
                      }}.png"
                      style="width: 40px"
                      alt="Card Type"
                    />
                  </div>
                  <div class="col-9 m-2">
                    **** -{{ selectedFullCard.last4 }}
                  </div>
                  <div class="col-1 ml-2">
                    <mat-icon [ngClass]="{ arrow: ShowCardList }">
                      keyboard_arrow_left
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="options" *ngIf="ShowCardList">
                <div
                  class="d-flex pt-2 mt-1 option1"
                  *ngFor="let card of cards"
                  (click)="selectedCardId(card)"
                >
                  <div><hr /></div>
                  <div class="col-1 m-2">
                    <img
                      data-src="assets/images/cardsIcons/{{ card.type }}.png"
                      style="width: 40px"
                      alt="Card Type"
                    />
                  </div>
                  <div class="col-9">
                    **** - {{ card.last4 }} {{ card.type }}
                  </div>
                </div>
              </div>
            </div>
            <span *ngIf="cards && thereAreCards" class="layout" (click)="show()"
              ><mat-icon *ngIf="!displayNewCard">play_arrow</mat-icon>
              <mat-icon *ngIf="displayNewCard">arrow_drop_down</mat-icon>
              New card
            </span>
            <div class="row" *ngIf="!thereAreCards || displayNewCard">
              <ea-form-field
                class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column"
                label="Card Number"
                name="cardNumber"
                alt="Card Number input"

                [form]="form"
              ></ea-form-field>
              <ea-form-field
                class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column"
                label="Expiry Month"
                name="expiryMonth"
                alt="Expiry Month input"
                [form]="form"
              ></ea-form-field>
              <ea-form-field
                class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column"
                label="Expiry Year"
                name="expiryYear"
                alt="Expiry Year input"
                [form]="form"
              ></ea-form-field>
              <ea-form-field
                class="form-field col-sm-12 col-md-3 d-flex justify-content-between align-items-between flex-column"
                label="CVC"
                name="cvc"
                alt="cvc input"
                [form]="form"
              ></ea-form-field>
            </div>
            <div class="mt-3" *ngIf="!thereAreCards || displayNewCard">
              <input
                type="checkbox"
                class="form-check-input focus-ring"
                [checked]="saveCart"
                (change)="saveCart = !saveCart"
                aria-label="Remember me"
                alt="save card checkbox"
              />
              Remember Me
            </div>
            <h2 class="m-1">
              Charity
              </h2>
            <div class="row m-2" *ngIf="options.length > 1">
            
              <select
                class="form-select border-gray-2px p-2  mb-2 ea-form bg-theme-surface focus-outline text-theme-on-surface"
                aria-label="Delivery methods"
                [(ngModel)]="selected"
                name="selectedOption"
                (ngModelChange)="onOptionsChanged($event)"
              >
                <option disabled>Select Default Charity Option</option>
                <option *ngFor="let option of options" [ngValue]="option">
                  <span
                    class="bg-theme-surface focus-outline text-theme-on-surface"
                  >
                    {{ option.charityName }} | {{ option.areaofFocusNames }}
                  </span>
                </option>
              </select>
              <label class="ea-label form-label" for="selectedOption">
                <small [attr.aria-label]="charityText">{{ charityText }}</small>
              </label>
            </div>
            <div class="row">
              <div class="col mt-5 mb-5">
                <ng-container *ngIf="error$ | async as error">
                  <div class="error text-red-500 font-bold">{{ error }}</div>
                </ng-container>
                <button
                  type="submit"
                  title="Save"
                  value="Save"
                  class="ea-button ea-button-primary col-sm-12 col-md-12 col-lg-12 col-xl-12 text-uppercase focus-ring w-100"
                  [disabled]="form.invalid || (isLoading$ | async)"
                >
                  <strong>Pay now</strong>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <ng-template #thankYou>
    <div
      class="card border-dark-gray-1px font-title bg-theme-surface focus-outline text-theme-on-surface"
    >
      <h2>
        Thank you for your order! Your order id is
        <a
          class="font-bold"
          [routerLink]="['/account/orders/order-details/', orderId]"
        >
          {{ orderId }}
        </a>
      </h2>
    </div>
  </ng-template>
</section>
