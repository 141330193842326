<section class="pb-5 pt-4">
  <div class="container">
    <div *ngFor="let order of orders" class="">
      <div class="header">
        <div class="text-theme-on-surface">
          <h1 class="font-bold">Order: # {{ order.orderId }}</h1>
        </div>
      </div>
      <div class="row justify-content-start pt-5">
        <div class="col-lg-3">
          <h2 class="font-bold">My items</h2>
        </div>

        <div class="col-lg-4"></div>
        <div class="col-lg-3" *ngIf="order.statusCode === orderStatus.Shipped">
          <a
            [routerLink]="['/account/orders/order-return/', order.orderId]"
            class="ea-button ea-button-primary focus-ring font-title mt-3"
          >
            Return Item (s)
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-9 pe-5">
        <div *ngFor="let order of orders">
          <div
            class="card mt-3 mb-5 font-title bg-theme-surface text-theme-on-surface"
          >
            <div *ngFor="let item of order.items">
              <div class="row">
                <div class="col-md-2 col-lg-2 mb-4">
                  <div class="pt-3"></div>
                  <img
                    [src]="item.product_image | getImageFilter"
                    alt="Product Image"
                  />
                </div>
                <div class="col-md-6 col-lg-6 pt-4">
                  <h3>{{ item.title }}</h3>
                  <br />
                  <p class="text-theme-on-surface">
                    Quantity: {{ item.orderedQuantity }}
                  </p>
                </div>
                <div class="col-md-4 col-lg-4 pt-2">
                  <div class="pt-3 pe-1 float-right text-right">
                    <p>
                      Item: {{ item.itemUnitPrice | currency : "GBP" }}
                      <br />
                      Total: {{ item.originalItemTotal | currency : "GBP" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="summary font-title">
            <div class="pt-3 pb-2">
              <h2 class="font-bold">Order summary</h2>
            </div>
            <div class="card mt-3 mb-5 bg-theme-surface text-theme-on-surface">
              <div class="row">
                <div class="col-lg-10">
                  Items Total<br />
                  Delivery Total<br />
                  VAT<br />
                </div>
                <div class="col-lg-2">
                  {{ order.orderSubTotal | currency : order.currency
                  }}<br /><!--Order Subtotal-->
                  {{ order.feeTotal | currency : order.currency
                  }}<br /><!--Order Delivery Total-->
                  <!-- {{ calculatedCurrency | currency }} VAT orderSubtotal * 1.05 -->
                  {{ order.vat     | currency : order.currency}}
                </div>
              </div>
              <hr class="text-medium-gray" />
              <div class="row">
                <div class="col-lg-10">Order Total</div>
                <div class="col-lg-2">
                  {{
                    order.orderSubTotal + order.feeTotal
                      | currency : order.currency
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="delivery-address">
            <div class="pt-3 pb-2">
              <h2 class="font-bold">Delivery address</h2>
            </div>
            <div class="card mt-3 mb-5 bg-theme-surface text-theme-on-surface">
              <div *ngIf="order.shipInfo && order.shipInfo.length > 0">
                <div class="row mt-2 mb-3">
                  <div class="col">
                    <p class="text-theme-on-surface">
                      {{ order.shipInfo[0].shipToAddress.name.first }}
                      {{ order.shipInfo[0].shipToAddress.name.last }}
                    </p>
                  </div>

                  <div class="col">
                    <p class="text-theme-on-surface">
                      {{
                        order.shipInfo[0].shipMethod
                          | split : " |"
                          | slice : 0 : 1
                      }}
                    </p>
                  </div>

                  <div class="mt-3">
                    <p class="text-theme-on-surface">
                      {{ order.shipInfo[0].shipToAddress.address1 }}<br />
                      {{ order.shipInfo[0].shipToAddress.city }},
                      {{ order.shipInfo[0].shipToAddress.state }}
                      {{ order.shipInfo[0].shipToAddress.postalCode }}<br />
                      {{ order.shipInfo[0].shipToAddress.country }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
