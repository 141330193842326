<div class="ea-card-grid col-md-12 rounded" [ngStyle]="styles">
  <div class="d-flex card-head">
    <div class="card-type">
      {{ cardHeader }}
    </div>
  </div>
  <div class="card-body d-flex align-items-center h-100">
    <div class="cards-img">
      <img class="card-img" [src]="cardImage" alt="" />
    </div>
    <div class="card-title">
      {{ cardTitle }}
    </div>
    <div class="card-icon" (click)="cardClosed.emit()">
      <mat-icon class="align-baseline">close</mat-icon>
    </div>
  </div>
</div>
