import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {  RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared';
import { LocalStorageService, ToastService } from '@ea/services';
import { CardItem, CreatePaymentMethod } from 'src/app/modules/cart/models/cart.model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormFieldComponent } from "../../../../components/form-field/form-field.component";
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Subject } from 'rxjs';
import { StripeService } from 'src/app/services/shared/Stripe/stripe.Service';

@Component({
    selector: 'app-my-cards-page',
    standalone: true,
    templateUrl: './my-cards-page.component.html',
    styleUrls: ['./my-cards-page.component.scss'],
    imports: [
        CommonModule,
        RouterLink,
        NgFor,
        NgIf,
        SharedModule,
        NgxSkeletonLoaderModule,
        FormFieldComponent,
        MatIconModule
    ]
})
export class MyCardsPageComponent implements OnInit {
  form: FormGroup;
  cards?: CardItem[];
  loader: boolean = true;
  error$ = new Subject<string>();
  displayNewCard: boolean = false;
  isLoading$ = new BehaviorSubject<boolean>(false);
  constructor(
    fb: FormBuilder,
    private storage: LocalStorageService,
    private toasterService: ToastService,
    private stripeService : StripeService
  ) {
    this.form = fb.group({
    cardNumber: [null, [Validators.required]],
    expiryMonth: [null, [Validators.required]],
    expiryYear: [null, [Validators.required]],
    cvc: [null, [Validators.required]],});
  }

  ngOnInit() {
    const sub = this.stripeService
      .GetUserCards(this.storage.getItem('customId'))
      .subscribe((x) => {
        this.cards = x;
        
        this.loader = false;
      });
  }

  getCardImageByType(cardType: string): string {
    return 'assets/images/cardsIcons/' + cardType + '.png';
  }

  deleteCard(paymentMethodId: string) {
    this.stripeService.deleteCart(paymentMethodId).subscribe({
      next: (x) => {
        if (x) {
          const myArray = this.cards?.filter((x) => {
            return x.paymentMethodId !== paymentMethodId;
          });
          this.cards = myArray;
          this.toasterService.show('Your card is deleted successfully');
        } else {
          this.toasterService.show("Sorry, we couldn't delete your card");
        }
      },
      error: () => {
        this.toasterService.show(
          "Error happened!! we couldn't delete your card"
        );
      },
    });
  }
  show() {
    this.displayNewCard = !this.displayNewCard;
    this.form.controls['cardNumber'].setValue(null);
    this.form.controls['expiryMonth'].setValue(null);
    this.form.controls['expiryYear'].setValue(null);
    this.form.controls['cvc'].setValue(null);
  }
  saveCard(){
    this.isLoading$.next(true);
    const raw = this.form.getRawValue();
    const request: CreatePaymentMethod = {
      cvc: raw.cvc,
      exp_month: raw.expiryMonth,
      exp_year: raw.expiryYear,
      number: raw.cardNumber,
      customId: this.storage.getItem('customId'),
      saveCart: true,
      userId: this.storage.getItem('user').userId
    };
    this.stripeService.createPaymentMethod(request)
    .subscribe((x: any) => {
      if(x.id){ 
        this.loader = true
        const sub = this.stripeService
        .GetUserCards(this.storage.getItem('customId'))
        .subscribe((x) => {
          this.cards = x;
          this.loader = false;
        });
        this.isLoading$.next(false);
        this.show();
        this.toasterService.show("Card added successfully");
      }
      else{
        this.toasterService.show("Error creating Card. Please check your card information and try again!");
      }
    },
    ((error: any) =>{
      this.isLoading$.next(false);
      this.toasterService.show("Error creating Card. Please check your card information and try again!");
    }))
  }
}

