<div class="container">
  <div class="py-3 mt-5">
    <h2 class="font-bold font-title">Select Delivery Options</h2>
  </div>

  <div *ngIf="cart; else skeleton">
    <ng-container *ngFor="let vendor of itemsByVendor">
      <div
        class="card border-dark-gray-1px font-title bg-theme-surface focus-outline text-theme-on-surface"
      >
        <ng-container *ngFor="let item of vendor.items">
          <div class="my-2">
            <div class="row">
              <div class="col-lg-3 mb-2">
                <div class="cart-item-image">
                  <img
                    class="aspect-square object-cover"
                    alt="{{ item | attribute: 'image_alt_main' }}"
                    src="{{
                      item | attribute: 'image_main_product' | getImageFilter
                    }}"
                  />
                </div>
              </div>
              <div class="col-lg-9 mb-2">
                <div class="cart-item-title d-flex flex-col justify-content-between h-100">
                  <div class="mb-2">
                  <p>Sold by {{ vendor.venorName }}</p>
                  <h3 class="font-bold"> {{ item.extra.parentName.value }} </h3>
                  <span
                  *ngIf="item.title != item.extra.parentName.value"
                  [innerHTML]="getItemTitle(item)"
                ></span>
                  </div>
                  <div
                    class="cart-item-price d-flex align-items-end justify-content-start justify-content-end"                  >
                    <span class="me-4">Quantity {{ item.quantity }}</span>
                    <span
                      >{{
                        item.totalPrice.amount +
                          item.extra.customValues.price * item.quantity
                          | currency: item.totalPrice.currency
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ea-delivery-options
          (optionChanged)="onSelectedOptionIsChanged($event, vendor)"
          [vendorId]="vendor.vendorId"
          [vendor] ="vendor"
          [shipmentMethodId]="vendor.shipTo?.shipMethod?.shipMethodId"
        ></ea-delivery-options>
      </div>
    </ng-container>
  </div>
  <ng-template #skeleton>
    <ngx-skeleton-loader
      count="1"
      [theme]="{
        'height.px': 100,
        'background-color': '#f3f3f3'
      }"
    ></ngx-skeleton-loader>
  </ng-template>
</div>
