<header>
  <!--MOBILE NAV-->
  <nav *ngIf="!readMode" class="navbar mobile-menu navbar-expand-lg mobile">
    <div class="container-fluid">
      <a
        [routerLink]="'/'"
        class="navbar-brand masthead-logo mobile"
        aria-label="home page"
      ></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
        <span class="counter" *ngIf="(this.cartProductsCount$ | async)">
            {{ cartProductsCount$ | async }}
          <label class="visually-hidden">
              items
            </label>
          </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ng-container>
          <ul class="navbar-nav">
            <ea-auth-links></ea-auth-links>
            <li
              class="nav-item bg-light-gray border-top-gray-1px ps-2"
              *ngFor="let category of categories"
            >
              <a
                title="{{ category.name }}"
                [attr.aria-label]="category.name"
                routerLink="/category/0/{{ category.id }}"
                [queryParams]="{ via: 'nav' }"
                routerLinkActive="active"
                class="nav-link"
                data-bs-target="#navbarNav"
                data-bs-toggle="collapse"
              >
                {{ category.name }}
              </a>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </nav>
  <!--end MOBILE MENU-->

  <div class="header-content desktop pt-4">
    <div class="row m-0 pb-0">
      <div class="col-md-3">
        <div class="logo justify-content-center me-3 row">
          <a
            [routerLink]="'/'"
            class="focus-ring masthead-logo justify-content-center"
            [title]="logoTitle"
            aria-label="EnableAll Home"
          >
            <span class="visually-hidden">EnableAll Home</span>
          </a>
          <button class="a11y-link focus-ring" tabindex="0" (click)="openA11yWidget()">Accessibility Tools</button>
        </div>
      </div>
      <ea-search-bar class="col-md-6"></ea-search-bar>

      <div class="col-md-3">
        <ea-auth-links></ea-auth-links>
      </div>
    </div>
  </div>
</header>
