import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IOrder, IUser } from '@ea/models';
import { OrderService } from 'src/app/services/shared/orders';
import { OrderDetailsPageComponent } from '../order-details-page/order-details-page.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BaseComponent } from '@ea/components';
import { FilterImagePipe } from 'src/app/modules/products/pipes';
import { AuthFacade } from '@ea/auth';
import { Observable, switchMap } from 'rxjs';

@Component({
  selector: 'ea-order-page',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    RouterLink,
    OrderDetailsPageComponent,
    FilterImagePipe,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss'],
})
export class OrderPageComponent extends BaseComponent implements OnInit {
  orders: IOrder[] = [];
  loader: boolean = false;
  user$: Observable<IUser>;

  constructor(
    private orderService: OrderService,
    private facade: AuthFacade,
  ) {
    super();
    this.user$ = this.facade.user$;
  }

  ngOnInit(): void {
    this.loader = true;
    this.subscriptions.push(
      this.user$
        .pipe(
          switchMap((user) => {
            this.loader = true;
            return this.orderService.getOrderByCustomerId(user.customId);
          }),
        )
        .subscribe((response) => {
          this.loader = false;
          this.orders = response.orders;
        }),
    );
  }
}
