<div
  class="p-4 bg-theme-surface text-theme-on-surface mb-3 border-dark-gray-1px font-title mb-4"
>
  <div *ngIf="address" class="p-2 card bg-theme-surface text-theme-on-surface">
    <p><span>Name:</span> {{address.first}}  {{address.last}}</p>
    <p><span>Phone:</span> {{address.phone.number}}</p>
    <p class="address-line">
       {{ transform(address.addressLine1)}},
       @if(address.addressLine2){
      <br><label *ngIf="address.addressLine2">{{ transform(address.addressLine2)}},</label>
       }
      @if(address.addressLine3){
        <br> <label >{{ transform(address.addressLine3)}},</label>
       }
      <br> {{ transform(address.city) }}
      ,
       
      <br>{{ transform(address.county) }}
      <br>{{address.postalCode}}
      <br>
      @if(address.country.toUpperCase() != 'GB'){
      {{ address.country.toUpperCase()   }}
      }
      @else{
        UK
      }
    </p>
  </div>

  <div class="row p-1">
    <ng-content></ng-content>
  </div>
</div>
