<section>
  <div class="container" style="width: 100%">
    <div style="padding: 0px; margin-bottom: 0px" *ngIf="loader; else content">
      <div class="row">
        <div class="col-sm-12 col-md-9 pt-5">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 200,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <ng-template #content>
      <div
        class="col-sm-12 col-md-9 d-flex flex-column justify-content-between mb-3"
      >
        <div class="cards-list row mx-auto rounded p-3 w-100">
          <h1>Charity</h1>
          <select
            class="form-select border-gray-2px p-2 mt-2 mb-2 ea-form bg-theme-surface focus-outline text-theme-on-surface"
            aria-label="Delivery methods"
            [(ngModel)]="selected"
            name="selectedOption"
            (ngModelChange)="onOptionsChanged($event)"
          >
            <option disabled>Select Charity Option</option>
            <option *ngFor="let option of options" [ngValue]="option">
              <span
                class="bg-theme-surface focus-outline text-theme-on-surface"
              >
                {{ option.charityName }} | {{ option.areaofFocusNames }}
              </span>
            </option>
          </select>
        </div>
      </div>
    </ng-template>
  </div>
</section>
