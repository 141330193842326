<div
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
  class="product-price"
  *ngIf="price?.sale"
>
  <h2 itemprop="price" class="text-theme-on-surface" *ngIf="price">
    <span [ngClass]="{ 'old-price': price.sale }">
      {{ price!.baseValue | currency : price!.currency }}
    </span>
    <span class="sale-price" *ngIf="price.sale ?? false">
      {{ price.sale | currency : price.currency }}
    </span>
  </h2>
</div>
<div
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
  class="product-price"
  *ngIf="
 !price?.sale && priceRange && (
    priceRange.min == 0 ||
    priceRange.max == 0 ||
    (priceRange.min == priceRange.max )|| !priceRange
  )
  "
>
  <h2 class="text-theme-on-surface" *ngIf="price ?? true">
    {{ priceRange?.max ?? 0 | currency : currency }}
  </h2>
</div>
<div class="product-price">
  <h2
    class="text-theme-on-surface"
    *ngIf="
      priceRange.min != 0 &&
      priceRange.max != 0 &&
      priceRange.min != priceRange.max &&
      !price!.sale
    "
  >
    {{ priceRange.min | currency : currency }}
    -
    {{ priceRange.max | currency : currency }}
  </h2>
</div>
