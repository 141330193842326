<div class="mt-2" *ngIf="options$ | async as options">
  Delivery
  @if(options.shipMethods.length == 1){option}
  @else{
  options
  } for {{ vendor.venorName }}
</div>
<select *ngIf="options$ | async as options"
  class="form-select font-bold border-gray-2px p-2 mt-2 mb-2 ea-form bg-theme-surface focus-outline text-theme-on-surface"
  aria-label="Delivery methods" [(ngModel)]="selected" (ngModelChange)="onOptionsChanged($event)">
  <option disabled>Select Delivery Option</option>
  <option *ngFor="let option of options.shipMethods" [ngValue]="option">
    <span class="bg-theme-surface focus-outline text-theme-on-surface">{{
      option.description
      }} </span>
    &nbsp; | &nbsp;
    <span class="bg-theme-surface focus-outline text-theme-on-surface" *ngIf="option.cost > 0">
      £{{
      transform(option.cost)
      }}</span>
    <span class="bg-theme-surface focus-outline text-theme-on-surface" *ngIf="option.cost == 0">
      Free
    </span>
  </option>

</select>




<!-- <div *ngIf="options$ | async as options">
<div
class="col-lg-12 col-md-12 selects"
(click)="showList()"
[ngClass]="{ highlight: ShowCardList }"
>
<div class="row m-2" *ngIf=" selected == ''">
  <div class="col-11 d-flex align-items-center">
    Choose card
  </div>
  <div class="col-1 d-flex align-items-center">
    <mat-icon [ngClass]="{ arrow: ShowCardList }">
      keyboard_arrow_left
    </mat-icon>
  </div>
</div>
<div class="row align-items-center p-2" *ngIf=" selected ">

  <div class="col-10 ">
    {{ selected.description }}
  </div>
  <div class="col-1 ">
    {{ selected.cost }}
  </div>
  <div class="col-1 ">
    <mat-icon [ngClass]="{ arrow: ShowCardList }">
      keyboard_arrow_left
    </mat-icon>
  </div>
</div>
</div>
<div class="options" *ngIf="ShowCardList">
<div
  class="d-flex pt-2 mt-1 option1"
  *ngFor="let card of  options.shipMethods"
  (click)="onOptionsChanged(card)"
>
  <div><hr /></div>
  <div class="col-11 m-2">
    {{card.description}}
  </div>
  <div class="col-1 m-2">
    {{card.cost}}
  </div>
</div>
</div>
</div> -->