import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { CartFacade } from '../../+state';
import { map, Observable } from 'rxjs';
import { CartItem, CartResponse, UpdateItemRequest } from '../../models';
import { AttributePipe } from '@ea/products';
import { CartTotalsComponent } from '../../components';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FilterImagePipe } from 'src/app/modules/products/pipes';
import { Store } from '@ngrx/store';
import { selectAuthState } from 'src/app/modules/auth/+state/auth.selector';
import {
  AddressService,
  LocalStorageService,
  ToastService,
} from '@ea/services';
import { BaseComponent } from '@ea/components';

@Component({
  selector: 'ea-cart-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    CartTotalsComponent,
    AttributePipe,
    FilterImagePipe,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss'],
})
export class CartPageComponent extends BaseComponent {
  constructor(
    private facade: CartFacade,
    private store: Store,
    private router: Router,
    private addressService: AddressService,
    private storage: LocalStorageService,
    private toasterService: ToastService,
  ) {
    super();
  }
  colorKey = 'colour';
  sizeKey = 'size';
  isLoading$?: Observable<boolean>;
  isLoaded$?: Observable<boolean>;
  cart$?: Observable<CartResponse>;
  isAuthenticated$ = new Observable<boolean>();
  ngOnInit(): void {
    this.cart$ = this.facade.cart$;
    this.cart$.subscribe(x =>{
      this.isLoading$ = this.facade.isLoading$;
      this.isLoaded$ = this.facade.isLoaded$;
      this.isAuthenticated$ = this.store
      .select(selectAuthState)
      .pipe(map((state) => state.isAuthenticated));

    })


  }

  removeItem(cartId: string, lineItemId: number) {
    this.facade.removeItem(cartId, lineItemId);
  }

  updateItem(cart: any, request: UpdateItemRequest) {
    this.facade.updateItem(cart.cartId, request);
  }

  itemQuantityChange(event: Event, cart: any, item: CartItem) {
    const quantity = (event.target as HTMLInputElement).value;
    const request: UpdateItemRequest = {
      cartId: cart.cartId,
      name: cart.metadata.name,
      items: [
        {
          itemId: item.itemId,
          lineItemId: item.lineItemId,
          priceListId: Number(item.priceListId),
          quantity: Number(quantity),
        },
      ],
    };
    this.updateItem(cart, request);
  }
  gotoCheckout() {
    this.toggleDisable(true);
    this.isAuthenticated$.subscribe((isAuth) => {
      if (isAuth) {
        const customId = this.storage.getItem('customId');
        this.subscriptions.push(
          this.addressService.getAddress(customId).subscribe((response) => {
            if (response && response.length > 0) {
              this.addressService.storeUserAddress(response);
              this.router.navigate(['/checkout']);
            } else {
              this.router.navigate(['/account/addresses']);
              this.toasterService.show('You need add address to checkout!');
            }
          }),
        );
      } else {
        const queryParams = { redirecrRoute: `cart` };
        this.router.navigate(['/login'], { queryParams });
      }
      this.toggleDisable(false);
    });
  }

  getItemTitle(item : CartItem){
    var title = '';
    if(item.attributes.filter(x => x.name == this.sizeKey).length > 0){
      title += `Size: ${item.attributes.filter(x => x.name == this.sizeKey)[0].value}<br>`
    }
    if(item.attributes.filter(x => x.name == this.colorKey).length > 0){
      title += `Colour: ${item.attributes.filter(x => x.name == this.colorKey)[0].value}\n`
    }
    if(title == ''){
      title = item.title;
    }
    return title;
  }
}
