import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@ea/components';

@Component({
  selector: 'ea-address',
  standalone: true,
  imports: [CommonModule, ],
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends BaseComponent {
  @Input() address?: any;
  transform(value: string): string {
    if (!value) {
      return value;
    }
    // Convert the entire string to lowercase
    value = value.toLowerCase();
    // Split the string into words
    const words = value.split(' ');
    // Capitalize the first character of each word
    for (let i = 0; i < words.length; i++) {
      if (words[i]) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
    }
    // Join the words back together with spaces
    return words.join(' ');
  }

}
