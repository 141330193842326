<section>
  <div class="container">
    <div class="header mb-5 px-3">
      <h1 class="font-bold">My Basket</h1>
    </div>
    <div class="row">
      <ng-container *ngIf="cart$ | async as cart">
        <div class="col-lg-8 mb-5">
          <div class="container">
            <div
              *ngIf="(isLoading$ | async) === true"
              style="padding: 0px; margin-bottom: 0px"
            >
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <ngx-skeleton-loader
                    count="1"
                    [theme]="{
                      'height.px': 100,
                      'background-color': '#f3f3f3'
                    }"
                  ></ngx-skeleton-loader>
                </div>
                <div class="col-md-6 col-lg-6">
                  <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                      'height.px': 25,
                      'background-color': '#f3f3f3'
                    }"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <!-- start card item -->
            <div class="row ps-3">
              <ng-container
                *ngIf="
                  cart.totalItems > 0 || (isLoading$ | async) === true;
                  else empty
                "
              >
                <ng-container *ngFor="let item of cart.items" >
                  <!-- card image -->
                  <div class="col-md-3 col-lg-3 cart-item-image mb-4">
                    <img
                      class="aspect-square object-cover"
                      alt="{{ item | attribute: 'image_alt_main' }}"
                      src="{{
                        item | attribute: 'image_main_product' | getImageFilter
                      }}"
                    />
                  </div>
                  <!-- end image -->
                  <!-- product-title -->
                  <div class="cart-item-detail col-md-9 col-lg-9 mb-4 px-4">
                    <div class="row justify-content-between mb-3">
                      <div class="col-7">
                        <a
                          class="cart-item-name"
                          [routerLink]="[
                            '/product-details',
                            item.extra.parentName.description
                          ]"
                          [title]="item.title"
                        >
                          {{ item.extra.parentName.value }}
                        </a>
                        <br>
                        <span
                          *ngIf="item.title != item.extra.parentName.value"
                          [innerHTML]="getItemTitle(item)"
                        ></span>
                        <!-- sold by -->
                        <div class="cart-item-vendor pb-2">
                          <span class="sold-by me-1">Sold by</span>
                          <span class="">{{
                              item | attribute: "vendor_name"
                            }}</span>
                        </div>
                      </div>
                      <!-- price amount -->
                      <div class="col-3">
                        <ng-container
                          *ngIf="item.unitPrice.discount?.discountAmount"
                        >
                          <div
                            class="base-price line-through text-gray-500 font-title"
                          >
                            {{
                              item.unitPrice.amount
                                | currency: item.unitPrice.currency
                            }}
                          </div>
                        </ng-container>
                        <div
                          class="font-title text-right font-bold"
                          *ngIf="
                            item.unitPrice.sale == item.extra.orginalPrice.price
                          "
                        >
                          {{
                            item.unitPrice.sale + item.extra.customValues.price
                              | currency: item.unitPrice.currency
                          }}
                        </div>
                        <div
                          class="font-title text-right font-bold"
                          *ngIf="
                            item.unitPrice.sale != item.extra.orginalPrice.price
                          "
                        >
                          <span Class="old-price px-2">{{
                            item.extra.orginalPrice.price +
                              item.extra.customValues.price
                              | currency: item.unitPrice.currency
                          }}</span>
                          <span
                            class="sale-price px-2"
                            *ngIf="
                              item.unitPrice.sale !=
                              item.extra.orginalPrice.price
                            "
                            >{{
                              item.unitPrice.sale +
                                item.extra.customValues.price | currency: "GBP"
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-between cart-item-font">
                      <div
                        class="col-xs-12 col-sm-6 flex flex-column justify-content-around"
                      >

                        <!-- Quantity -->
                        <div class="flex align-items-end mt-2">
                          <fieldset class="quantity-box mr-2">
                            <label
                              for="quantity"
                              class="font-title text-left cart-item-font w-100"
                              >Quantity</label
                            >
                            <input
                              [value]="item.quantity"
                              id="quantity"
                              type="number"
                              alt="quantity input"
                              class="ea-input font-title input-style py-2"
                              [disabled]="(isLoading$ | async) === true"
                              (change)="itemQuantityChange($event, cart, item)"
                            />
                          </fieldset>
                          <button
                            [disabled]="isLoading$ | async"
                            (click)="removeItem(cart.cartId, item.lineItemId)"
                            title="Remove item"
                            class="ea-button mt-2 font-bold cart-item-font text-right"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-5 quantity-col">
                        <!-- total price -->

                        <div class="text-right font-bold font-title pt-2 total-cost">
                          <div
                            class="base-price line-through text-gray-500 font-title"
                            *ngIf="item.totalPrice.discount?.discountAmount"
                          >
                            {{
                              item.totalPrice.amount
                                | currency: item.unitPrice.currency
                            }}
                          </div>
                          <div>
                            {{
                              item.totalPrice.sale +
                                item.extra.customValues.price * item.quantity
                                | currency: item.totalPrice.currency
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="card-end ml-3 mt-4"></div>
                  <br>  <br>  <br>

                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- end card item -->
        <div class="col-lg-4">
          <!--          <div class="mt-5"></div>-->
          <div
            *ngIf="(isLoading$ | async) === true"
            style="padding: 0px; margin-bottom: 0px"
          >
            <div class="row">
              <div class="col-lg-12">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{
                    'height.px': 150,
                    'background-color': '#f3f3f3'
                  }"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="subtotal-content col-lg-12">
            <ng-container *ngIf="cart$ | async as cart">
              <ng-container *ngIf="cart.totalItems > 0">
                <div
                  class="card bg-theme-surface focus-outline text-theme-on-surface"
                >
                  <div class="row ps-2 pe-2 cart-totals">
                    <ea-cart-totals></ea-cart-totals>
                  </div>
                  <div class="row ps-3 pe-3">
                    <button
                      (click)="gotoCheckout()"
                      class="ea-button ea-button-primary text-uppercase w-100 p-1 font-title font-bold"
                      [disabled]="isButtonsDisabled$ | async"
                    >
                      Go to Delivery
                    </button>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-template #empty>
      <p class="text-theme-on-background">You have no items in your basket</p>
    </ng-template>
  </div>
</section>
