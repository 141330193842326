import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cards-grid',
  templateUrl: './cards-grid.component.html',
  styleUrls: ['./cards-grid.component.scss'],
})
export class CardsGridComponent {
  @Input() styles?: any;
  @Input() cardHeader?: string;
  @Input() cardTitle?: string;
  @Input() cardImage?: string;

  @Output() cardClosed = new EventEmitter();
}
