import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { cartSelectors } from '../../cart/+state/cart.selectors';
import { selectAuthState } from '../+state/auth.selector';

@Component({
  selector: 'ea-auth-links',
  standalone: true,
  imports: [CommonModule, RouterLink, MatIconModule],
  templateUrl: './auth-links.component.html',
  styleUrls: ['./auth-links.component.scss'],
})
export class AuthLinksComponent implements OnInit {
  isAuthenticated$ = new Observable<boolean>();
  cartProductsCount$ = new Observable<string | number>();
  showCount: boolean = false;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.store
      .select(selectAuthState)
      .pipe(map((state) => state.isAuthenticated));

    this.cartProductsCount$ = this.store
      .select(cartSelectors.getItemsInTheCart)
      .pipe(map((count) => (count > 0 ? count : '')));
    this.cartProductsCount$.subscribe((x) => {
      this.showCount = x != '';
    });
  }
}
