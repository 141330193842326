import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const authGuard = () => {
  const router = inject(Router);
  let user = localStorage.getItem('user');

  if (user) return true;
  else return router.parseUrl('/login');
};
