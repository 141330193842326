<nav class="px-5">
    <ul id="navigation" class="container px-5">
        <ng-container *ngFor="let category of categories">
            <li>
                <a
                        title="{{ category.name |titlecase }}"
                        [attr.aria-label]="category.name"
                        [routerLink]="['/category/0/', category.id]"
                        [queryParams]="{ via: 'nav' }"
                        [routerLinkActive]="['active']"
                        class="focus-ring link border-b-4 border-b-transparent block font-title"
                >
                    {{ category.name }}
                </a>
            </li>
        </ng-container>
    </ul>
</nav>
