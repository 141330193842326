<section>
  <div class="container" style="width: 100%;">
    <div style="padding: 0px; margin-bottom: 0px" *ngIf="loader; else content">
      <div class="row">
        <div class="col-sm-12 col-md-9 pt-5">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                'height.px': 200,
                'background-color': '#f3f3f3'
              }"
            ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <ng-template #content>
      <div class="container">
        <div class="row">
          <div class="account pt-5 pb-5">
            <div class="col-sm-12 col-md-9 d-flex flex-column justify-content-between mb-3">
              <div class="cards-list row mx-auto rounded p-3 w-100">
                <div class="font-title font-bold pb-3 h1">User Cards</div>
                <span  class="layout" (click)="show()"
                ><mat-icon *ngIf="!displayNewCard">play_arrow</mat-icon>
                <mat-icon *ngIf="displayNewCard">arrow_drop_down</mat-icon> New
                card</span
              >
              <div *ngIf="displayNewCard">
              <div class="row">
                <ea-form-field
                  class="form-field col-sm-12 col-md-6 d-flex justify-content-between align-items-between flex-column"
                  label="Card Number"
                  name="cardNumber"
                  [form]="form"
                ></ea-form-field>
                <ea-form-field
                  class="form-field col-sm-12 col-md-6 d-flex justify-content-between align-items-between flex-column"
                  label="Expiry Month"
                  name="expiryMonth"
                  [form]="form"
                ></ea-form-field>
                <ea-form-field
                  class="form-field col-sm-12 col-md-6 d-flex justify-content-between align-items-between flex-column"
                  label="Expiry Year"
                  name="expiryYear"
                  [form]="form"
                ></ea-form-field>
                <ea-form-field
                  class="form-field col-sm-12 col-md-6 d-flex justify-content-between align-items-between flex-column"
                  label="CVC"
                  name="cvc"
                  [form]="form"
                ></ea-form-field>
              </div>
              <div class="row">
                <ng-container *ngIf="error$ | async as error">
                  <div class="error text-red-500 font-bold">{{ error }}</div>
                </ng-container>
                <div class="col mt-5 mb-5">
                  <button
                    (click)=" saveCard()"
                    title="Save"
                    value="Save"
                    class="ea-button ea-button-primary col-sm-12 col-md-12 col-lg-12 col-xl-12 text-uppercase focus-ring w-100"
                    [disabled]="form.invalid || (this.isLoading$ |async) "
                  >
                    <strong>Save</strong>
                  </button>
                </div>
              </div>
            </div>
              <br>  <br>  <br>
                <div class="mt-4" *ngIf="(!cards || cards.length == 0) && !loader">
                  <p class="text-theme-on-background">You have no cards yet</p>
                </div>
                <div *ngFor="let card of cards" class="col-12 col-lg-6 mb-2 payment-card">
                  <app-cards-grid [cardImage]="getCardImageByType(card.type)" [cardTitle]="'**** '+card.last4" [styles]="{height:'47px', backgroundColor: '#f7f7f7', minWidth:'max-content'}" (cardClosed)="deleteCard(card.paymentMethodId)"></app-cards-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</section>
