import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardsGridComponent } from './components';
import { MatIconModule } from '@angular/material/icon';

const exported = [CardsGridComponent];
@NgModule({
  declarations: exported,
  imports: [CommonModule, MatIconModule],
  exports: exported,
  providers: [],
})
export class SharedModule {}
