import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { BaseComponent, BreadcrumbComponent } from '@ea/components';
import { ICategory, IImageGallery, IProductSearchResult } from '@ea/models';
import { CategoryService, ProductService } from '@ea/services';
import { FilterImagePipe } from '../../pipes';
import { FilteredImageService } from 'src/app/services/shared/filter-image/filtered-image.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  Attribute2,
  AttributeBase,
  AttributesResponse,
  Product,
  productAddItem,
  ProductDetails,
  ProductLikeRequest,
} from 'src/app/models/productDetails.model';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DataLayerService } from 'src/app/services/shared/WindowReference/DataLayerService';
import environment from 'src/environments/environment';
import { ItemPriceComponent } from '../../components/item-price/item-price.component';
import { ProductLikeService } from 'src/app/services/shared/ProductLiks/productLikes.service';
import { catchError, map, Observable, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from 'src/app/modules/auth/+state';
import {
  ProductAddToBasketComponent,
  ProductGridComponent,
} from '../../components';
import { SyneriseService } from 'src/app/modules/category/services/synerise.service';

declare let SR: any;

@Component({
  selector: 'ea-product-details',
  standalone: true,
  imports: [
    CommonModule,
    ProductAddToBasketComponent,
    RouterLink,
    BreadcrumbComponent,
    FilterImagePipe,
    NgxSkeletonLoaderModule,
    FormsModule,
    MatIconModule,
    ItemPriceComponent,
    ProductGridComponent,
  ],
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent extends BaseComponent implements OnInit {
  public get firstProduct(): Product | undefined {
    const product = this.product?.products[0];
    return product;
  }

  public get livePrice() {
    return this.firstProduct?.livePrice
      ? this.firstProduct.livePrice[0]
      : undefined;
  }

  colorKey = 'colour';
  sizeKey = 'size';
  product?: ProductDetails;
  image!: IImageGallery;
  isAuthenticated$ = new Observable<boolean>();
  sku!: string;
  currentIndex = 0;
  mainImage = '';
  altMainImage = '';
  desc: string = '';
  breadcrumbs!: any[];
  originalSalePrice: number = 0;
  originalPrice: number = 0;
  isShowLinkForAccount: boolean = false;
  category!: ICategory;
  displayName: string = '';
  vendor_name: string = '';
  product_features: any[] = [];
  maxPrice: number = 0;
  minPrice: number = 0;
  images: any[] = [];
  addProductItem!: productAddItem;
  display_key_value_with_bullet_list: any[] = [];
  display_bulletpoint_list: any[] = [];
  display_text: any[] = [];
  textList: AttributeBase[] = [];
  variants: any[] = [];
  sizes: any[] = [];
  colors: any[] = [];
  selectedSize: string = '';
  selectedColor: string = '';
  selectedName: number = 0;
  breadcrumbsString: string = '';
  vendor_node_id: any;
  vendor_id: any;
  eventModel: any;
  isLoading$: boolean = true;

  sizeOrder: Record<string, number> = {
    'Extra Small': 0,
    Small: 1,
    Medium: 2,
    Large: 3,
    'Extra Large': 4,
    'Extra Extra Large': 5,
    'Extra Extra Extra Large': 6,
    Tall: 7,
  };
  sizeOrderChar: Record<string, number> = {
    XS: 0,
    S: 1,
    M: 2,
    L: 3,
    XL: 4,
    '2XL': 5,
    XXL: 6,
    '3XL': 7,
    XXXL: 8,
    '4XL': 9,
    '5XL': 10,
    '6XL': 11,
  };

  sizeNames: Record<string, string> = {
    XS: 'X Small',
    S: 'Small',
    M: 'Medium',
    L: 'Large',
    XL: 'X Large',
    '2XL': '2X Large',
    XXL: '2X Large', // 'XXL' and '2XL' represent the same size in this case
    '3XL': '3X Large',
    XXXL: '3X Large', // 'XXXL' and '3XL' represent the same size in this case
    '4XL': '4X Large',
    '5XL': '5X Large',
    '6XL': '6X Large',
  };

  isBrowser = true;
  currency = environment.stripe.country == 'GB' ? '£' : '$';
  data: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private productService: ProductService,
    private route: ActivatedRoute,
    private filterImageService: FilteredImageService,
    private dataLayer: DataLayerService,
    private title: Title,
    private metaService: Meta,
    private productLikeService: ProductLikeService,
    private router: Router,
    private store: Store,
    private syneriseService: SyneriseService,
    private categoryService: CategoryService
  ) {
    super();
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  categoriesKey = 'additionalCategories';

  ngOnInit(): void {
    this.product = undefined;
    this.isLoading$ = true;
    const sku = this.route.snapshot.params['sku'];
    this.sku = sku;

    const subscription = this.productService
      .getProductSku(sku)
      .pipe(
        catchError((err) => {
          throw err;
        })
      )
      .subscribe((product: ProductDetails) => {
        this.product = product;

        product.products[0].parent
          ? this.loadSyneriseListAndCollections(product.products[0].parent)
          : this.loadSyneriseListAndCollections(sku);
        this.loadSmallerProducts(sku);
        if (this.livePrice) {
          const price = this.livePrice;
          if (price) {
            this.originalSalePrice = price.price.sale;
          }

          this.originalPrice = price.price.cost;
        }

        this.setVendorData();
        this.productService.choose$.next(true);
        this.setPrice();
        this.setDescription();
        this.setProductMainData();
        this.setProductFeature();
        this.handelProductsAttributes();
        this.handelProductItems();
        this.updateMetaTags();
        this.updateIsAuthenticated();
        this.orginalColors = this.colors;
        this.orginalSizes = this.sizes;
        this.addSchemaOrg();
        this.isLoading$ = false;
      });

    this.subscriptions.push(subscription);
  }

  private updateIsAuthenticated() {
    this.isAuthenticated$ = this.store
      .select(selectAuthState)
      .pipe(map((state) => state.isAuthenticated));
  }
  loadProduct() {
    if (this.isBrowser) {
      this.product = undefined;
      this.isLoading$ = true;
      window.location.reload();
    }
  }
  private loadSyneriseListAndCollections(sku: any) {
    const subscription = this.syneriseService
      .list({
        additionalCategories: '',
        caseSensitiveFacetValues: true,
        clientUUID: '',
        facets: [this.categoriesKey],
        filters: `sku=="${sku}"`,
        includeFacets: 'all',
        limit: 1,
        page: 1,
        includeMeta: false,
      })
      .pipe(
        switchMap((x) => {
          const categories = x.extras.filteredFacets[this.categoriesKey];
          const keys = Object.keys(categories);
          const newkey: string[] = [];
          this.categoryService.rootCategories$.value.forEach((cat) => {
            keys.forEach((x) => {
              if (x.includes(cat.breadcrumbString)) {
                newkey.push(x);
              }
            });
          });
          const index = newkey.length > 1 ? 1 : 0;
          return this.loadCollections(newkey[index]);
        })
      )
      .subscribe();

    this.subscriptions.push(subscription);
  }

  private loadSmallerProducts(sku: string) {
    this.subscriptions.push(
      this.syneriseService.sameProducts(sku).subscribe((x) => {
        this.data = x;
      })
    );
  }

  private loadCollections(names: string) {
    return this.categoryService.getCollections(names).pipe(
      tap((x: any) => {
        this.breadcrumbs = x.map((y: any) => {
          return {
            name: y.name,
            link: [`/category/0`, y.id],
            id: y.id,
          };
        });
        this.breadcrumbs = this.breadcrumbs.filter((x) => x.name != 'uk main');
        this.sendGoogleEvent(this.displayName);
      })
    );
  }

  getAttrByName(
    attrs: Attribute2[] | AttributesResponse[] | undefined,
    name: string
  ) {
    return attrs?.find((x) => x.name == name);
  }

  getAttrValueByName(attrs: Attribute2[] | AttributesResponse[], name: string) {
    return this.getAttrByName(attrs, name)?.value;
  }

  setVendorData() {
    if (this.product) {
      const attrs = this.product.products[0].attributes;
      this.vendor_node_id = this.getAttrValueByName(attrs, 'vendor_node_id');
      this.vendor_id = this.getAttrValueByName(attrs, 'vendor_id');
      this.vendor_name = this.getAttrValueByName(attrs, 'vendor_name');
    }
  }
  isNumeric(value: string): boolean {
    return !isNaN(parseFloat(value)) && isFinite(Number(value));
  }
  sortSizes(): void {
    if (this.sizes[0].value.includes('/')) {
      this.sizes = this.sortSizesByOrder(this.sizes);
    } else if (this.isNumeric(this.sizes[0].value)) {
      this.sizes.sort((a, b) => parseInt(a.value) - parseInt(b.value));
    } else {
      this.sizes = this.sortSizesByOrder(this.sizes);
    }
  }
  handelProductItems() {
    if (this.firstProduct) {
      this.addProductItem = {
        itemId: this.firstProduct.itemId,
        price: this.firstProduct.livePrice
          ? this.firstProduct.livePrice[0].price
          : this.firstProduct.priceRange.max,
        type: this.firstProduct.type,
        mainImage: this.mainImage,
        parentName: this.displayName,
        parentSku: this.sku,
        options: '',
        additionalprice: 0,
      };

      this.handleItemsForProduct(this.firstProduct);
    }

    this.checkValidAddToBasket();

    // Notify productService based on the length of variants
    if (this.variants.length > 0) {
      this.addProductItem.itemId = 0;
      this.checkValidAddToBasket();
    }

    // Calculate max and min prices if variants exist
    if (this.variants.length > 0 && this.firstProduct) {
      const livePrices = this.firstProduct.items.map(
        (item) => item.livePrices[0].price.baseValue
      );
      this.maxPrice = Math.max(...livePrices);
      this.minPrice = Math.min(...livePrices);
    }

    // Sort sizes if sizes array is not empty
    if (this.sizes.length > 0) {
      this.sortSizes();
    }
    if (this.colors.length > 0) {
      this.colors.sort((a, b) => a.value.localeCompare(b.value));
    }
  }

  private handleItemsForProduct(product: Product) {
    product.items.forEach((item) => {
      const attrs = item.attributes;
      const variant = {
        itemId: item.itemId,
        url: this.getAttrValueByName(attrs, 'image_main_product') ?? null,
        name: this.getAttrValueByName(attrs, 'display_name') ?? null,
        hasStock: item.hasStock,
      };
      this.variants.push(variant);

      // Check for sizes and colors and add them to the respective arrays
      const sizeAttribute = this.getAttrByName(attrs, this.sizeKey);
      const colorAttribute = this.getAttrByName(attrs, this.colorKey);

      if (sizeAttribute) {
        const matchSize = this.sizes.find(
          (size) => size.value === sizeAttribute.value
        );

        if (!matchSize) {
          this.sizes.push({
            itemId: item.itemId,
            hasStock: item.hasStock,
            value: sizeAttribute.value,
          });
        } else if (matchSize && !matchSize.hasStock) {
          matchSize.hasStock = item.hasStock;
        }
      }

      if (colorAttribute) {
        const matchColor = this.colors.find(
          (color) => color.value === colorAttribute.value
        );
        if (!matchColor) {
          this.colors.push({
            itemId: item.itemId,
            hasStock: item.hasStock,
            value: colorAttribute.value,
          });
        } else if (matchColor && !matchColor.hasStock) {
          matchColor.hasStock = item.hasStock;
        }
      }
    });
  }

  handelProductsAttributes() {
    const excludedAttributes = [
      'display_name',
      'product_description',
      'vendor_name',
    ];
    const allowedDescriptions = [
      'display_key_value_with_bullet_list',
      'display_bulletpoint_list',
      'display_text',
      'display_value_list',
    ];

    if (this.firstProduct) {
      this.display_text = this.firstProduct.attributes.filter(
        (x) =>
          !excludedAttributes.includes(x.name) &&
          allowedDescriptions.includes(x.description)
      );
    }

    for (const element of this.display_text) {
      let item: AttributeBase = {
        name: this.convertString(element.name),
        type: element.description,
        value: [],
      };

      if (item.type == 'display_text') {
        item.value.push(element.value);
      }

      if (item.type == 'display_key_value_with_bullet_list') {
        item.value = JSON.parse(this.convertDictionary(element.value));
      } else if (element.value) {
        let tempAtt = JSON.parse(element.value);
        tempAtt.forEach((x: any) => {
          if (x && x != '') {
            x = x.trim().replace(/^\\n\s*/g, '');
            item.value.push(x);
          }
        });
      }

      this.textList.push(item);
    }
  }

  setDescription() {
    const descriptionAttribute = this.getAttrByName(
      this.firstProduct?.attributes,
      'product_description'
    );

    if (descriptionAttribute?.value) {
      const descriptions = descriptionAttribute.value.split(/\n/);
      let isTitleList = false;
      for (const description of descriptions) {
        if (
          description !== 'Includes:' &&
          description !== 'Features:' &&
          !isTitleList
        ) {
          this.desc += `<div class='text-theme-on-surface mt-3 mb-2 content'>${description}</div>`;
        } else if (
          description !== 'Includes:' &&
          description !== 'Features:' &&
          isTitleList
        ) {
          this.desc += `<ul><li>. ${description}</li></ul>`;
        } else {
          isTitleList = true;
          this.desc += `<br><strong>${description}</strong>`;
        }
      }
    }
  }

  setProductFeature() {
    let productFeatures = this.getAttrByName(
      this.firstProduct?.attributes,
      'product_features'
    );

    if (productFeatures) {
      this.product_features = JSON.parse(productFeatures.value);
    }
  }

  setProductMainData() {
    if (this.firstProduct) {
      const attrs = this.firstProduct.attributes;
      this.displayName = this.getAttrValueByName(attrs, 'display_name') ?? '';

      const result = JSON.parse(
        this.getAttrValueByName(attrs, 'image_gallery')
      );

      this.images = Object.values(result);
      this.mainImage = this.getAttrValueByName(attrs, 'image_main_product');
      this.altMainImage = this.getAttrValueByName(attrs, 'image_alt_main');
      let mainImageExist = this.images.find((x) => x.isMain);
      if (!mainImageExist) {
        this.images.push({
          imageAlt: this.altMainImage,
          imageURL: this.mainImage,
          isMain: true,
          position: 0,
        });
      }
    }
  }

  setPrice() {
    if (this.firstProduct && this.firstProduct.items.length > 0) {
      this.firstProduct.livePrice = this.firstProduct.items[0].livePrices;
    }
  }

  updateMetaTags() {
    this.metaService.updateTag({
      property: 'og:title',
      content: this.displayName,
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: this.desc,
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: this.mainImage,
    });
    this.metaService.updateTag({
      property: 'og:image:alt',
      content: this.altMainImage,
    });
    this.metaService.updateTag({
      name: 'twitter:title',
      content: this.displayName,
    });
    this.metaService.updateTag({
      name: 'twitter:description',
      content: this.desc,
    });
    this.metaService.updateTag({
      name: 'description',
      content: this.desc,
    });
    this.metaService.updateTag({
      name: 'product:retailer_part_no',
      content: this.sku,
    });

    this.title.setTitle(this.displayName + ' | EnableAll');
  }

  sortSizesByOrder(sizes: any[]): any[] {
    return sizes.sort((a, b) => {
      if (this.hasNumericPortion(this.sizes[0].value)) {
        const numericOrderA = this.extractNumericPortion(a.value);
        const numericOrderB = this.extractNumericPortion(b.value);
        return numericOrderA - numericOrderB;
      } else {
        const orderA =
          this.sizeOrder[a.value] !== undefined
            ? this.sizeOrder[a.value]
            : this.sizeOrderChar[a.value];
        const orderB =
          this.sizeOrder[b.value] !== undefined
            ? this.sizeOrder[b.value]
            : this.sizeOrderChar[b.value];
        return orderA - orderB;
      }
    });
  }

  hasNumericPortion(value: string): boolean {
    return value.includes('/');
  }

  extractNumericPortion(value: string): number {
    const parts = value.split('/');

    return parseInt(parts[0]) || parseInt(parts[1]);
  }

  sendGoogleEvent(itemVariantName: string) {
    let x: any = {
      event: 'item_view',
      ecommerce: {
        item_name: this.displayName,
        item_id: this.sku,
        item_price: this.firstProduct?.livePrice
          ? this.firstProduct.livePrice[0].price
          : this.firstProduct?.priceRange.max,
        item_brand: this.vendor_name,
        quantity: 1,
        item_variant: itemVariantName,
      },
    };
    this.breadcrumbsString += this.breadcrumbs[0].name;
    for (let i = 0; i < this.breadcrumbs.length; i++) {
      const cat = this.breadcrumbs[i].name;
      const str =
        'x.ecommerce.item_category' + (i + 1) + '=' + '"' + cat + '";';
      eval(str);
      if (i != 0) {
        this.breadcrumbsString += '>' + this.breadcrumbs[i].name;
      }
    }
    this.eventModel = x;
    this.dataLayer.SendEvent(x);
  }

  showImage(index: number) {
    this.currentIndex = index;
    this.mainImage = this.images[index].imageURL;
  }

  nextImage() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.mainImage = this.images[this.currentIndex].imageURL;
  }

  prevImage() {
    this.currentIndex =
      (this.currentIndex - 1 + this.images.length) % this.images.length;
    this.mainImage = this.images[this.currentIndex].imageURL;
  }

  getImage(galleryItem: IProductSearchResult): string {
    const base = 'https://res.cloudinary.com/revcascade/';
    const query = this.filterImageService.imageParams;

    return galleryItem.image_main_product.replace(base, `${base}${query}`);
  }
  convertString(str: string) {
    str = str.replace(/_/g, ' ');

    return str[0].toUpperCase() + str.slice(1);
  }
  convertDictionary(str: string) {
    str = str.replace(/\\"/g, '');
    str = str.replace(/\\/g, '');
    return str[0].toUpperCase() + str.slice(1);
  }

  ChooseSizeAndColor(key: string) {
    let selectedValue = '';
    let searchAttribute = '';
    let selectedItem: any[] = [];
    console.log('ZZ');
    // Determine selected value, search attribute, and find selected item
    if (key === this.sizeKey) {
      selectedValue = this.selectedSize;
      searchAttribute = this.colorKey;
      selectedItem = this.findSelectedItem(this.sizes, selectedValue);
    } else {
      selectedValue = this.selectedColor;
      searchAttribute = this.sizeKey;
      selectedItem = this.findSelectedItem(this.colors, selectedValue);
    }
    const items = this.findItemByAttributeValue(selectedValue, key);
    this.updateColorsOrSizes(key, items, searchAttribute);
    if (selectedItem.length > 0) {
      const item = this.findItemById(selectedItem[0].itemId);
      if (item) {
        const itemName = this.getAttrValueByName(
          item.attributes,
          'display_name'
        );

        if (itemName) {
          this.sendGoogleEvent(itemName);
        }

        // Update main image and additional images
        this.updateImages(item);

        // Update colors or sizes array based on key

        // Check flag to update addProductItem
        const flag = this.checkFlag();

        // Update addProductItem if flag is true
        if (flag) {
          this.updateAddProductItem(item);
        }
      } else {
        this.clearFilter();
      }
    } else {
      this.clearFilter();
    }
  }

  findSelectedItem(items: any[], selectedValue: string): any[] {
    if (this.colors.length == 0 || this.sizes.length == 0) {
      return items.filter((item) => item.value === selectedValue);
    } else {
      if (this.selectedColor && !this.selectedSize) {
        var newItems = this.firstProduct?.items.filter(
          (item) =>
            item.attributes.filter(
              (att) =>
                att.value == this.selectedColor && att.name == this.colorKey
            ).length > 0
        );
        var selectedItems = [];
        if (newItems) {
          for (const i of newItems) {
            selectedItems.push({
              itemId: i.itemId,
              hasStock: i.hasStock,
              value: i.attributes.find((x) => x.name == this.colorKey)?.value,
            });
          }
        }
        return selectedItems;
      } else if (!this.selectedColor && this.selectedSize) {
        var newItems = this.firstProduct?.items.filter(
          (item) =>
            item.attributes.filter(
              (att) =>
                att.value == this.selectedSize && att.name == this.sizeKey
            ).length > 0
        );
        var selectedItems = [];
        if (newItems) {
          for (const i of newItems) {
            selectedItems.push({
              itemId: i.itemId,
              hasStock: i.hasStock,
              value: i.attributes.find((x) => x.name == this.sizeKey)?.value,
            });
          }
        }
        return selectedItems;
      } else if (this.selectedColor && this.selectedSize) {
        var newItems = this.firstProduct?.items.filter(
          (item) =>
            item.attributes.filter(
              (att) =>
                att.value == this.selectedSize && att.name == this.sizeKey
            ).length > 0 &&
            item.attributes.filter(
              (att) =>
                att.value == this.selectedColor && att.name == this.colorKey
            ).length > 0
        );
        var selectedItems = [];
        if (newItems) {
          for (const i of newItems) {
            selectedItems.push({
              itemId: i.itemId,
              hasStock: i.hasStock,
              value: i.attributes.find((x) => x.name == this.sizeKey)?.value,
            });
          }
        }
        return selectedItems;
      }
      return [];
    }
  }

  findItemById(itemId: number): any {
    return this.firstProduct?.items.find((item) => item.itemId === itemId);
  }

  findItemByAttributeValue(value: string, key: string): any {
    if (value) {
      return this.firstProduct?.items.filter(
        (item) =>
          item.attributes.filter((att) => att.value == value && att.name == key)
            .length > 0
      );
    } else {
      return this.firstProduct?.items;
    }
  }

  updateColorsOrSizes(key: string, item: any, searchAttribute: string): void {
    if (key === this.sizeKey && this.colors?.length > 0) {
      if (this.selectedSize) {
        this.colors = this.orginalColors.filter(
          (x: any) =>
            item.filter(
              (iet: any) =>
                iet.hasStock &&
                iet.attributes.filter((att: any) => att.value == x.value)
                  .length > 0
            ).length > 0
        );
      } else {
        this.colors = this.orginalColors;
      }
    } else if (key === this.colorKey && this.sizes?.length > 0) {
      if (this.selectedColor) {
        this.sizes = this.orginalSizes.filter(
          (x: any) =>
            item.filter(
              (iet: any) =>
                iet.hasStock &&
                iet.attributes.filter((att: any) => att.value == x.value)
                  .length > 0
            ).length > 0
        );
      } else {
        this.sizes = this.orginalSizes;
      }
    }
  }

  createColorOrSizeObject(item: any, searchAttribute: string): any {
    return {
      itemId: item.itemId,
      hasStock: item.hasStock,
      value: this.getAttrValueByName(item.attributes, searchAttribute),
    };
  }

  checkFlag(): boolean {
    let flag = false;
    if (this.colors.length > 0 && this.sizes.length > 0) {
      flag = this.selectedColor !== '' && this.selectedSize !== '';
    } else if (this.colors.length > 0 && this.sizes.length === 0) {
      flag = this.selectedColor !== '';
    } else if (this.colors.length === 0 && this.sizes.length > 0) {
      flag = this.selectedSize !== '';
    }

    return flag;
  }

  updateAddProductItem(item: any): void {
    this.addProductItem.itemId = item.itemId;
    this.checkValidAddToBasket();
    const livePrice = item.livePrice
      ? item.livePrice[0].price
      : item.livePrices[0].price;
    this.addProductItem.price = livePrice;

    if (this.firstProduct) {
      this.firstProduct.livePrice = item.livePrice
        ? item.livePrice
        : item.livePrices;
      this.firstProduct.priceRange.max =
        this.firstProduct.livePrice[0].price.baseValue;
      this.firstProduct.priceRange.min =
        this.firstProduct.livePrice[0].price.baseValue;
    }

    this.maxPrice = 0;
    this.minPrice = 0;
  }

  clearFilter() {
    this.colors = [];
    this.sizes = [];
    this.selectedSize = '';
    this.selectedColor = '';
    this.selectedName = 0;
    this.variants = [];

    this.resetFirstProductIfExists();

    this.selectedName = 0;
    this.checkValidAddToBasket();
  }

  getSizeAlt(value: string) {
    return this.sizeNames[value] ?? value;
  }

  private resetFirstProductIfExists() {
    if (this.firstProduct) {
      const result = JSON.parse(
        this.getAttrValueByName(this.firstProduct.attributes, 'image_gallery')
      );

      this.images = Object.values(result);
      const mainImage = this.images.find((x) => x.isMain);
      this.mainImage = mainImage.imageURL;
      this.altMainImage = mainImage.imageAlt;

      this.resetProductItems(this.firstProduct);

      this.addProductItem.itemId = this.firstProduct.itemId;
    }
  }

  orginalColors: any;
  orginalSizes: any;
  private resetProductItems(product: Product) {
    for (const element of product.items) {
      const attrs = element.attributes;

      this.variants.push({
        itemId: element.itemId,
        hasStock: element.hasStock,
        url: this.getAttrValueByName(attrs, 'image_main_product'),
        name: this.getAttrValueByName(attrs, 'display_name'),
      });

      const sizeAttr = this.getAttrByName(attrs, this.sizeKey);
      if (sizeAttr) {
        const sizes = this.sizes.filter((x) => x.value == sizeAttr.value);
        if (sizes.length == 0) {
          this.sizes.push({
            itemId: element.itemId,
            hasStock: element.hasStock,
            value: sizeAttr.value,
          });
        }
      }

      const colorAttr = this.getAttrByName(attrs, this.colorKey);

      if (colorAttr) {
        const colors = this.colors.filter((x) => x.value == colorAttr.value);
        if (colors.length == 0) {
          this.colors.push({
            itemId: element.itemId,
            hasStock: element.hasStock,
            value: colorAttr.value,
          });
        }
      }
    }
  }

  selectName() {
    if (!this.selectedName || this.selectedName === 0) {
      this.clearFilter();
      return;
    }

    const selectedItem = this.firstProduct?.items.find(
      (x) => x.itemId == this.selectedName
    );

    if (selectedItem) {
      // Update main image and additional images
      this.updateImages(selectedItem);

      // Update addProductItem and send Google event
      this.updateAddProductItemAndEvent(selectedItem);
    }

    // Notify ProductService to choose
    if (this.selectedName !== 0) {
      this.productService.choose$.next(true);
    }
  }

  updateImages(selectedItem: any): void {
    const imageGalleryAttribute = this.getAttrByName(
      selectedItem.attributes,
      'image_gallery'
    );

    if (imageGalleryAttribute) {
      const result = JSON.parse(imageGalleryAttribute.value);
      this.mainImage = result[0].imageURL;
      this.altMainImage = result[0].imageAlt;
      this.images =
        Object.keys(result).length > 1 ? Object.values(result) : this.images;
    } else {
      this.images = [];
    }
  }

  updateAddProductItemAndEvent(selectedItem: any): void {
    this.addProductItem.itemId = this.selectedName;
    const variantName = this.variants.find(
      (x) => x.itemId == this.selectedName
    )?.name;
    if (variantName) {
      this.sendGoogleEvent(variantName);
    }

    const livePrice = selectedItem.livePrice
      ? selectedItem.livePrice[0].price
      : selectedItem.livePrices[0].price;

    this.addProductItem.price = livePrice;

    if (this.firstProduct) {
      this.firstProduct.livePrice = selectedItem.livePrice
        ? selectedItem.livePrice
        : selectedItem.livePrices;

      if (selectedItem.priceRange != null) {
        this.firstProduct.priceRange = selectedItem.priceRange;
      } else {
        const priceRange = this.firstProduct.priceRange;
        priceRange.max = livePrice.baseValue;
        priceRange.min = 0;
      }
    }

    this.maxPrice = 0;
    this.minPrice = 0;
  }

  triggerLike() {
    this.toggleDisable(true);
    const subscription = this.isAuthenticated$.subscribe((isAuth) => {
      if (isAuth) {
        this.likeTheProduct();
      } else {
        const queryParams = { redirecrRoute: `product-details/${this.sku}` };
        this.router.navigate(['/login'], { queryParams });
      }
      this.toggleDisable(false);
    });

    this.subscriptions.push(subscription);
  }

  private likeTheProduct() {
    const request: ProductLikeRequest = {
      itemId: this.addProductItem.itemId,
      mainImage: this.mainImage,
      productSku: this.sku,
      title: this.displayName,
      vendorName: this.vendor_name,
      imageAlt: this.altMainImage ?? this.displayName + 'image',
      breadcrumbsString: this.breadcrumbsString,
    };

    const subscription = this.productLikeService
      .triggerProductLike(request)
      .subscribe((x: any) => {
        this.trackSyneriseFavorites(!this.product?.products[0].userLiked);
        if (this.firstProduct) {
          this.firstProduct.userLiked = x;
          x
            ? this.firstProduct.productLikes++
            : this.firstProduct.productLikes--;
        }

        this.isShowLinkForAccount = x;
      });

    this.subscriptions.push(subscription);
  }

  selectOption(option: any) {
    if (option.type == 'list') {
      let totalAdd = 0;

      if (this.firstProduct) {
        this.firstProduct.options
          .filter((x) => x.type == 'list')
          .forEach((x) => {
            if (x.selectedValue) {
              totalAdd += x.selectedValue.value;
            }
          });

        const livePrice = this.firstProduct.livePrice[0];
        if (livePrice) {
          if (livePrice.price.sale) {
            livePrice.price.sale = this.originalSalePrice + totalAdd;
          } else {
            livePrice.price.cost = this.originalPrice + totalAdd;
          }
        }
      }

      this.addProductItem.additionalprice = totalAdd;
    }

    let resultString = '{';

    const firstProduct = this.firstProduct;
    if (firstProduct) {
      firstProduct.options.forEach((option, index) => {
        if (option.selectedValue) {
          const value =
            option.type === 'list'
              ? `${option.selectedValue.name}`
              : option.selectedValue;
          resultString += `"${option.displayName}":"${value}"`;
          if (index < firstProduct.options.length - 1) {
            resultString += ',';
          }
        }
      });
    }

    resultString += '}';
    this.addProductItem.options = resultString;
    this.checkValidAddToBasket();
  }

  checkValidAddToBasket() {
    let optionsSelected = true;
    if (this.firstProduct?.options) {
      this.firstProduct.options.forEach((x) => {
        if (!x.selectedValue) {
          optionsSelected = false;
        }
      });
    }

    const itemId = this.addProductItem.itemId;

    if (itemId && optionsSelected) {
      optionsSelected = true;
    }

    if (itemId && itemId != 0 && optionsSelected) {
      optionsSelected = true;
    } else {
      optionsSelected = false;
    }

    this.productService.choose$.next(optionsSelected);
  }

  trackSyneriseFavorites(isLiked: boolean) {
    const event = isLiked
      ? 'product.addToFavorite'
      : 'product.removeFromFavorite';
    SR.event.trackCustomEvent(event, {
      sku: this.sku,
    });
  }

  trackSyneriseVisit() {
    SR.event.pageVisit();
  }
  convertDateTime(param: string) {
    // Extract the date part from the parameter string
    const dateString = param.replace('Release date: ', '').trim();
    const [day, month, year] = dateString.split(' ');
    const formattedDateString = `${month} ${day.replace(/\D/g, '')}, ${year}`; // Remove ordinal suffix and format
    const targetDate = new Date(formattedDateString);
    const now = new Date();
    if (now < targetDate) {
      return true;
    } else {
      return false;
    }
  }

  addSchemaOrg() {
    var structuredData = {};
    if (
      !this.product?.products[0].items ||
      this.product?.products[0].items.length == 0
    ) {
      structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: this.displayName,
        image: this.mainImage,
        description: this.desc ? this.product?.products[0].attributes.filter(
          (att) => att.name == 'product_description'
        )[0].value: "",
        productGroupID: this.product?.products[0].parent,
        sku: this.sku,
        brand: {
          '@type': 'Brand',
          name: this.vendor_name,
        },
        offers: {
          '@type': 'Offer',
          url: `${environment.ukDomainUrl}/product-details/${this.sku}`,
          priceCurrency: 'GBP',
          price: this.livePrice?.price.baseValue,
          priceSpecification: {
            '@type': 'PriceSpecification',
            price: this.livePrice?.price.baseValue,
            priceCurrency: 'GBP',
          },
          review: {
            "@type": "Review",
            reviewRating: {},
            author: {}
          },
       aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: 0,
        reviewCount: 0
      },
          availability: 'https://schema.org/InStock',
        },
      };
    } else {
      var hasVariant: {
        '@type': string;
        sku: string;
        image: any;
        name: any;
        description: string;
        color: any;
        size: any;
        offers: {
          '@type': string;
          url: string;
          priceCurrency: string;
          price: number;
          priceSpecification: {
            '@type': string;
            price: number;
            priceCurrency: string;
    
          };
          availability: string;
          review : any,
          aggregateRating : any
        };
      }[] = [];
      this.product?.products[0].items.filter(x => x.hasStock).forEach((x) =>
        hasVariant.push({
          '@type': 'Product',
          sku: x.sku,
          image: x.attributes.filter(
            (att) => att.name == 'image_main_product'
          ).length > 0 ?x.attributes.filter(
            (att) => att.name == 'image_main_product'
          )[0].value: this.mainImage,
          name: x.attributes.filter((att) => att.name == 'display_name')[0]
            .value,
          description: this.desc ? this.product?.products[0].attributes.filter(
            (att) => att.name == 'product_description'
          )[0].value! : "",
          color:
            x.attributes.filter((att) => att.name == this.colorKey).length > 0
              ? x.attributes.filter((att) => att.name == this.colorKey)[0].value
              : null,
          size:
            x.attributes.filter((att) => att.name == 'size').length > 0
              ? x.attributes.filter((att) => att.name == 'size')[0].value
              : null,
          offers: {
            '@type': 'Offer',
            url: `${environment.ukDomainUrl}/product-details/${x.sku}`,
            priceCurrency: 'GBP',
            price: x.livePrices[0].price.baseValue,
            priceSpecification: {
              '@type': 'PriceSpecification',
              price: x.livePrices[0].price.baseValue,
              priceCurrency: 'GBP',
            },
            availability: 'https://schema.org/InStock',
            review: {
              "@type": "Review",
              reviewRating: {},
              author: {}
            },
         aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: 0,
          reviewCount: 0
        },
          },
        })
      );
      let variesBy = [];
      if (this.orginalSizes?.length > 0 && this.orginalColors?.length > 0) {
        variesBy = ['https://schema.org/color', 'https://schema.org/size'];
      } else if (
        this.orginalColors?.length == 0 &&
        this.orginalSizes?.length > 0
      ) {
        variesBy = ['https://schema.org/size'];
      } else {
        variesBy = ['https://schema.org/color'];
      }
      structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'ProductGroup',
        name: this.displayName,
        url: `${environment.ukDomainUrl}/product-details/${this.sku}`,
        description:  this.desc? this.product?.products[0].attributes.filter(
          (att) => att.name == 'product_description'
        )[0].value : "",
        productGroupID: this.sku,
        brand: {
          '@type': 'Brand',
          name: this.vendor_name,
        },
        offers: {
          '@type': 'Offer',

          priceCurrency: 'GBP',
          price: this.livePrice?.price.baseValue,
          priceSpecification: {
            '@type': 'PriceSpecification',
            price: this.livePrice?.price.baseValue,
            priceCurrency: 'GBP',
          },
          availability: 'https://schema.org/InStock',
        },
        hasVariant: hasVariant,
        variesBy: variesBy,
      };
    }
    if (this.isBrowser) {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(structuredData);
      document.head.appendChild(script);
    }
  }
}
