<div class="search-bar pt-3">
  <div class="col-sm-12">
    <div class="container text-center">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <div class="search-box input-group pb-4">
            <label class="visually-hidden" [for]="inputId">Search</label>
            <input
              type="search"
              [(ngModel)]="searchValue"
              class="ea-input form-control-flex font-title border-right-0"
              [id]="inputId"
              placeholder="I'm looking for..."
              (search)="search()"
              (keyup.enter)="search()"
              alt="search input"
              #input
            />

            <button
              (click)="search()"
              class="bg-theme-secondary text-theme-on-secondary p-3 focus-ring d-flex align-items-center"
            >
              <span
                class="material-icons bg-theme-secondary text-theme-on-secondary"
                >search
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
