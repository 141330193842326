<div class="add-to-basket" *ngIf="product">
  <form [formGroup]="form" *ngIf="form" (ngSubmit)="addToBasket()">
    <div class="row mt-5 d-flex justify-content-center align-items-center">
      <div class="col-lg-3">
        <label class="form-label" for="quantity"> Quantity </label>
        <input
          class="ea-input form-control ps-3 p-1 mb-2 min-size"
          formControlName="quantity"
          type="number"
          id="quantity"
          value="1"
          min="1"
          (change)="changeQuantity()"
          (keypress)="onlyAllowNumbers($event)"
          alt="quantity input"
        />
      </div>

      <div class="col-lg-9">
        <button
          type="submit"
          *ngIf="(productsInTheCartCount$ | async) ?? 0 == 0; else updateButton"
          [disabled]="!(this.productService.choose$ |async) || (isButtonsDisabled$ | async)"
          class="submit ea-button ea-button-primary focus-ring height mt-4"
        >
          ADD TO BASKET
        </button>

        <ng-template #updateButton>
          <button
            type="submit"
            class="submit ea-button ea-button-primary focus-ring"
          >
            UPDATE BASKET
          </button>
        </ng-template>
      </div>
    </div>
  </form>
</div>
