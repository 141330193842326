@if(isBrowser && !product && isLoading$){
<div class="container" style="margin-bottom: 0px">
  <div class="row">
    <div class="col-md-6 m-4">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'height.px': 30,
          'background-color': '#f3f3f3'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div class="row ml-4 mb-4">
    <div class="col-md-6">
      <ngx-skeleton-loader
        [theme]="{
          'height.px': 500,
          'background-color': '#f3f3f3'
        }"
      >
      </ngx-skeleton-loader>
    </div>
    <div class="col-md-6">
      <ngx-skeleton-loader
        [theme]="{
          'height.px': 80,
          'background-color': '#f3f3f3'
        }"
      ></ngx-skeleton-loader>
      <div class="col-md-8">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 58,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="row">
        <div class="col-md-3">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 50,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
        <div class="col-md-9">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 50,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
      <div class="col-md-12">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 100,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="col-md-12">
        <ngx-skeleton-loader
          [theme]="{
            'height.px': 150,
            'background-color': '#f3f3f3'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
} @else{
<div *ngIf="product && !isLoading$">
  <section class="pt-3 breadcrumbs">
    <ng-container *ngIf="breadcrumbs && breadcrumbs.length > 0">
      <ea-breadcrumb [items]="breadcrumbs"></ea-breadcrumb>
    </ng-container>
    <div class="container pt-2">
      <div class="mb-8"></div>
      <div class="row" itemscope >
        <div
          class="product-display-right pe-5 col-sm-12 col-md-12 col-lg-6 col-xl-6"
        >
          <!-- PRODUCT IMAGE GALLERY-->
          <ng-container *ngIf="this.images">
            <div class="product-image mainImage">
              <img
                itemprop="image"
                [src]="
                  mainImage || 'assets/images/placeholder.png' | getImageFilter
                "
                [alt]="altMainImage"
              />
            </div>

            <div class="buttons" *ngIf="this.images.length > 1">
              <div class="row">
                <div class="prev col-lg-6 col-xl-6">
                  <button
                    class="font-title bg-theme-surface text-theme-on-surface"
                    (click)="prevImage()"
                  >
                    PREVIOUS
                  </button>
                </div>
                <div class="next col-lg-6 col-xl-6">
                  <button
                    class="font-title bg-theme-surface text-theme-on-surface"
                    (click)="nextImage()"
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </div>

            <div
              class="product-image-thumbnails"
              *ngIf="this.images.length > 1"
            >
              <div class="row">
                <div
                  class="col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-4"
                  *ngFor="let image of this.images; let i = index"
                >
                  <img
                    class="thumbnail-active"
                    [ngClass]="{ active: i === currentIndex }"
                    [src]="image.imageURL | getImageFilter"
                    [alt]="image.imageAlt"
                    (click)="showImage(i)"
                  />
                </div>
              </div>
            </div>
          </ng-container>

          <!-- END PRODUCT IMAGE GALLERY END-->
        </div>

        <div
          class="product-display-left ps-5 col-sm-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div>
            <div class="d-flex">
              <div class="mb-0 mr-2 p-0" (click)="triggerLike()">
                <span
                  class="m-2 like product-display-right"
                  *ngIf="product.products[0].userLiked"
                >
                  <mat-icon>favorite</mat-icon>
                </span>
                <span
                  class="m-2 unlike product-display-right"
                  *ngIf="!product.products[0].userLiked"
                >
                  <mat-icon class="unlike">favorite_border</mat-icon>
                </span>
              </div>
              <a
                *ngIf="isShowLinkForAccount"
                class="like"
                href="account"
                style="margin-left: -50px"
              >
                My Favorite
              </a>
            </div>

            <hr />
            <br />
            <h1 class="ms-2" itemprop="name">{{ this.displayName }}</h1>

            <div *ngIf="product" class="product-sold-by">
              <p class="text-theme-on-surface">
                Sold by
                <a
                  [routerLink]="[
                    '/vendors/',
                    this.vendor_node_id,
                    this.vendor_id
                  ]"
                  ><strong> {{ this.vendor_name }}</strong>
                </a>
              </p>
            </div>
            <ea-item-price
              [price]="livePrice?.price"
              [priceRange]="firstProduct?.priceRange"
            ></ea-item-price>
          </div>
          <div class="row mt-3 container" *ngIf="this.variants.length > 0">
            <div class="row">
              <div class="col-6" *ngIf="sizes.length > 0">
                <label for="size" style="font-weight: bold" class="mb-3">Size</label>
              </div>
              <div class="col-6" *ngIf="colors.length > 0">
                <label for="color" style="font-weight: bold" class="mb-3">Color</label>
              </div>
              <div
                class="col-6"
                *ngIf="colors.length == 0 && sizes.length == 0"
              >
                <label for="Choices" style="font-weight: bold" class="mb-3">Choices</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <select
                id="Choices"
                  [(ngModel)]="this.selectedName"
                  (ngModelChange)="selectName()"
                  class="form-select bg-theme-surface focus-outline text-theme-on-surface"
                  *ngIf="colors.length == 0 && sizes.length == 0"
                >
                  <option></option>
                  <option
                    *ngFor="let x of variants"
                    [value]="x.itemId"
                    [disabled]="!x.hasStock"
                  >
                    <span
                      class="bg-theme-surface focus-outline text-theme-on-surface"
                    >
                      {{ x.name }}
                    </span>
                  </option>
                </select>
              </div>
              <div class="col-6" *ngIf="sizes.length > 0">
                <select
                  id="size"
                  [(ngModel)]="this.selectedSize"
                  (ngModelChange)="ChooseSizeAndColor('size')"
                  class="form-select bg-theme-surface focus-outline text-theme-on-surface"
                >
                  <option></option>
                  <option
                    *ngFor="let x of sizes"
                    [value]="x.value"
                    [disabled]="!x.hasStock"
                    class="bg-theme-surface focus-outline text-theme-on-surface"
                    [attr.aria-label]="getSizeAlt(x.value)"
                  >
                    {{ x.value }}
                  </option>
                </select>
              </div>
              <div class="col-6" *ngIf="colors.length > 0">
                <select
                  id="color"
                  [(ngModel)]="this.selectedColor"
                  (ngModelChange)="ChooseSizeAndColor(this.colorKey)"
                  class="form-select bg-theme-surface focus-outline text-theme-on-surface"
                >
                  <option></option>
                  <option
                    *ngFor="let x of colors"
                    [value]="x.value"
                    [disabled]="!x.hasStock"
                    class="bg-theme-surface focus-outline text-theme-on-surface"
                    [attr.aria-label]="x.value"
                  >

                      {{ x.value }}
                  </option>
                </select>
              </div>
            </div>
            <br />
          </div>

          <div
            class="row mt-3 container"
            *ngIf="
              firstProduct &&
              firstProduct.options &&
              firstProduct.options.length > 0
            "
          >
            <div *ngFor="let option of firstProduct.options" class="mt-2">
              {{ option.displayName }}
              <div class="col-12" *ngIf="option.type == 'text'">
                <textarea
                  rows="5"
                  style="width: 100%"
                  (ngModelChange)="selectOption(option)"
                  [(ngModel)]="option.selectedValue"
                >
                </textarea>
              </div>
              <div class="col-12" *ngIf="option.type == 'list'">
                <select
                  class="form-select bg-theme-surface focus-outline text-theme-on-surface"
                  [(ngModel)]="option.selectedValue"
                  (ngModelChange)="selectOption(option)"
                >
                  <option></option>
                  <option *ngFor="let x of option.choices" [value]="x">
                    <span
                      class="bg-theme-surface focus-outline text-theme-on-surface"
                      >{{ x.name }}</span
                    >
                  </option>
                </select>
              </div>
            </div>
          </div>
          <ea-product-add-to-basket
            [product]="addProductItem"
            [eventModel]="eventModel"
            [breadcrumbsString]="breadcrumbsString"
          ></ea-product-add-to-basket>
          <div *ngFor="let item of textList">
            <div class="product-description mt-5 px-3"  *ngIf="item.name =='Pre order information'">
              <div class="card bg-theme-surface text-theme-on-surface">
                <span style="font-weight: bold" class="mb-3">
                  {{ item.name }}
                </span>
                <hr />
                <div
                  class="mt-3"
                  *ngIf="item.type == 'display_bulletpoint_list'"
                >
                  <ul>
                    <li *ngFor="let point of this.item.value" class="mt-1">
                      <span
                        *ngIf="
                          point[0] == '-' &&
                          point != '' &&
                          point != 'Product Features:' &&
                          point
                        "
                      >
                        {{ point.replace("-", "") }}
                      </span>
                      <span
                        *ngIf="
                          point[0] == '•' &&
                          point != '' &&
                          point != 'Product Features:' &&
                          point
                        "
                      >
                        {{ point.replace("•", "") }}
                      </span>
                      <span
                        *ngIf="
                          point[0] != '-' &&
                          point[0] != '•' &&
                          point != '' &&
                          point != 'Product Features:' &&
                          point
                        "
                      >
                        {{ point }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="desc.length > 0" class="product-description mt-5 px-3">
            <div class="card bg-theme-surface text-theme-on-surface">
              <span style="font-weight: bold" class="mb-3">
                Product description
              </span>
              <hr />

              <div itemprop="description" [innerHTML]="this.desc"></div>
            </div>
          </div>
          <div *ngFor="let item of textList">
            <div class="product-description mt-5 px-3" *ngIf=" item.name !='Pre order information'">
              <div class="card bg-theme-surface text-theme-on-surface">
                <span style="font-weight: bold" class="mb-3">
                  {{ item.name }}
                </span>
                <hr />
                <div
                  [innerHTML]="item.value[0]"
                  *ngIf="item.type == 'display_text'"
                ></div>
                <div
                  class="mt-3"
                  *ngIf="item.type == 'display_bulletpoint_list' "
                >
                  <ul>
                    <li *ngFor="let point of this.item.value" class="mt-1">
                      <span
                        *ngIf="
                          point[0] == '-' &&
                          point != '' &&
                          point != 'Product Features:' &&
                          point
                        "
                      >
                        {{ point.replace("-", "") }}
                      </span>
                      <span
                        *ngIf="
                          point[0] == '•' &&
                          point != '' &&
                          point != 'Product Features:' &&
                          point
                        "
                      >
                        {{ point.replace("•", "") }}
                      </span>
                      <span
                        *ngIf="
                          point[0] != '-' &&
                          point[0] != '•' &&
                          point != '' &&
                          point != 'Product Features:' &&
                          point
                        "
                      >
                        {{ point }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="mt-3" *ngIf="item.type == 'display_value_list'">
                  <div class="mb-2" *ngFor="let point of this.item.value">
                    <span *ngIf="point[0] == '-'">
                      {{ point.replace("-", "") }}
                    </span>
                    <span *ngIf="point[0] != '-'">
                      {{ point }}
                    </span>

                    <br />
                    <br />
                    <hr />
                  </div>
                </div>
                <div
                  class="mt-3"
                  *ngIf="item.type == 'display_key_value_with_bullet_list'"
                >
                  <table style="width: 100%">
                    <tr class="mt-1" *ngFor="let point of this.item.value">
                      <td class="m-2" *ngFor="let i of point.split('%')">
                        <span *ngIf="i[0] == '-'">
                          <span
                            *ngIf="
                              i == point.split('%')[0] &&
                              point.split('%').length > 1
                            "
                            >{{ i.replace("-", "") }}%</span
                          >
                          <span
                            *ngIf="
                              i != point.split('%')[0] ||
                              point.split('%').length == 1
                            "
                            >{{ i.replace("-", "") }}</span
                          >
                        </span>
                        <span *ngIf="i[0] != '-'">
                          <span
                            *ngIf="
                              i == point.split('%')[0] &&
                              point.split('%').length > 1
                            "
                            >{{ i }}%</span
                          >
                          <span
                            *ngIf="
                              i != point.split('%')[0] ||
                              point.split('%').length == 1
                            "
                            >{{ i }}</span
                          >
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  class="mt-3"
                  *ngIf="
                    item.type == 'display_key_value_with_bullet_list_colon'
                  "
                >
                  <table style="width: 100%">
                    <tr class="mt-1" *ngFor="let point of this.item.value">
                      <td class="m-2" *ngFor="let i of point.split(':')">
                        <span *ngIf="i[0] == '-'">
                          <span
                            *ngIf="
                              i == point.split('%')[0] &&
                              point.split(':').length > 1
                            "
                          >
                            {{ i.replace("-", "") }}
                          </span>
                          <span
                            *ngIf="
                              i != point.split('%')[0] ||
                              point.split(':').length == 1
                            "
                          >
                            {{ i.replace("-", "") }}
                          </span>
                        </span>
                        <span *ngIf="i[0] != '-'">
                          <span
                            *ngIf="
                              i == point.split('%')[0] &&
                              point.split(':').length > 1
                            "
                          >
                            {{ i }}
                          </span>
                          <span
                            *ngIf="
                              i != point.split('%')[0] ||
                              point.split(':').length == 1
                            "
                          >
                            {{ i }}
                          </span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="product-container"
          data-read-mode-ignore
          *ngIf="data && data.data && data.data.length > 0"
        >
          <h1 tabindex="0" class="mt-2" alt="Products you may also like">
            Products you may also like
          </h1>
          <div id="products" #products class="products-grid">
            <ea-product-grid
              (click)="loadProduct()"
              *ngFor="let product of data.data"
              [product]="product"
            ></ea-product-grid>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!product">
        <h2>No Product</h2>
      </ng-container>
    </div>
  </section>
</div>
}
