<!--MOBILE MENU-->

<div class="auth-links-mobile mobile">
  <ng-container *ngIf="isAuthenticated$ | async; else noAuthMobile">
    <li class="nav-item bg-light-gray ps-2">
      <a
        [routerLink]="'account'"
        class="nav-link font-title font-bold"
        href="#"
        data-bs-target="#navbarNav"
        data-bs-toggle="collapse"
      >
        Account
      </a>
    </li>

    <li class="nav-item bg-light-gray border-top-gray-1px ps-2">
      <a
        [routerLink]="'cart'"
        class="nav-link font-title font-bold active"
        aria-current="page"
        data-bs-target="#navbarNav"
        data-bs-toggle="collapse"
      >
        Basket

        <span class="mobile-counter float-right" *ngIf="this.showCount">
          {{ cartProductsCount$ | async }}
        </span>
      </a>
    </li>
  </ng-container>

  <ng-template #noAuthMobile>
    <li class="nav-item bg-light-gray border-top-gray-1px ps-2">
      <a
        routerLink="/login"
        class="nav-link font-title font-bold active"
        aria-current="page"
        data-bs-target="#navbarNav"
        data-bs-toggle="collapse"
      >
        Log in
      </a>
    </li>

    <li class="nav-item bg-light-gray border-top-gray-1px ps-2">
      <a
        [routerLink]="'cart'"
        class="nav-link font-title font-bold active"
        aria-current="page"
        data-bs-target="#navbarNav"
        data-bs-toggle="collapse"
      >
        Basket

        <span class="mobile-counter float-right" *ngIf="this.showCount">
          {{ cartProductsCount$ | async }}
        </span>
      </a>
    </li>
  </ng-template>
</div>

<!--END MOBILE MENU-->

<div class="desktop mt-3">
  <div class="row">
    <div class="col-md-6 col-lg-6 p-0">
      <ng-container *ngIf="isAuthenticated$ | async; else noAuth">
        <div class="hide-mobile account float-right">
          <a
            [routerLink]="'/account'"
            class="ea-button ea-button-primary w-100 focus-ring"
          >
            <span class="material-icons">
              <mat-icon>account_circle</mat-icon>
            </span>

            Account
          </a>
        </div>
      </ng-container>

      <ng-template #noAuth>
        <div class="hide-mobile login float-right">
          <a
            routerLink="/login"
            class="ea-button ea-button-primary w-100 focus-ring"
          >
            <span class="material-icons"><mat-icon>login</mat-icon></span>

            Log in
          </a>
        </div>
      </ng-template>
    </div>

    <div class="col-md-6 col-lg-6 pe-0">
      <div class="hide-mobile basket float-left">
        <a
          routerLink="/cart"
          class="ea-button ea-button-primary w-100 focus-ring"
        >
          <span class="material-icons">
            <mat-icon>shopping_cart</mat-icon>
          </span>

          Basket
          <label
            class="visually-hidden"
            for="autoSizingInputGroup"
            *ngIf="this.showCount"
          >
            with
          </label>

          <span class="counter" *ngIf="this.showCount">
            {{ cartProductsCount$ | async }}
            <label class="visually-hidden" for="autoSizingInputGroup">
              items
            </label>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
