import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BaseComponent } from '@ea/components';
import { IResponseAddress } from '@ea/models';
import { AddressService, LocalStorageService } from '@ea/services';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { BehaviorSubject } from 'rxjs';
import { AddressComponent } from '../../components';

@Component({
  selector: 'ea-addresses-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgxSkeletonLoaderModule,
    AddressComponent,
  ],
  templateUrl: './addresses-page.component.html',
  styleUrls: ['./addresses-page.component.scss'],
})
export class AddressesPageComponent extends BaseComponent implements OnInit {
  // addressId!: string;
  addressResults = new BehaviorSubject<IResponseAddress[]>([]);
  filteredAddressList: IResponseAddress[] = []; // addressId and isDefault

  // default address is computed from the addressResults if there is one with isDefault set to true. use rxjs filter operator
  defaultAddress!: IResponseAddress | null;
  addressId!: string;
  loader: boolean = false;

  constructor(
    private addressService: AddressService,
    private storage: LocalStorageService
  ) {
    super();
  }

  ngOnInit() {
    // check if address is default

    this.subscriptions.push(
      this.addressResults.subscribe((addresses) => {
        this.defaultAddress =
          addresses.find((address) => address.isDefault) || null;

        this.filteredAddressList = addresses.filter(
          (address) => address.addressId !== this.defaultAddress?.addressId
        );
      })
    );

    const customId = this.storage.getItem('customId');

    if (customId) {
      this.loader = true;
      this.subscriptions.push(
        this.addressService.getAddress(customId).subscribe((response) => {
          this.addressResults.next(response);
          this.loader = false;
        })
      );
    }
  }
  toggleDefault(address: IResponseAddress, isDefault: boolean) {
    this.toggleDisable(true);
    const customId = this.storage.getItem('customId');
    this.subscriptions.push(
      this.addressService
        .updateAddress({
          addressId: address.addressId,
          isDefaultAddress: isDefault,
          customId: customId,
          isShippingAddress: true,
          isBillingAddress: true,
          currentDefault: this.defaultAddress?.addressId || null,
          type: address.type,
          addressLineOne: address.addressLine1,
          addressLineTwo: address.addressLine2,
          addressLineThree: address.addressLine3,
          addressCity: address.city,
          addressCounty: address.county,
          addressPostcode: address.postalCode,
          addressCountry: address.country,
          phone: address.phone.number,
        })
        .subscribe({
          next: (result: any) => {
            // update the address list
            this.addressResults.next(
              this.addressResults.value.map((address) => {
                if (address.addressId === result.addressId) {
                  return result;
                }
                if (isDefault) {
                  address.isDefault = false;
                }
                return address;
              })
            );
            this.toggleDisable(false);
          },
          error: () => {
            this.toggleDisable(false);
          },
        })
    );
  }

  deleteAddress(addressId: string) {
    this.subscriptions.push(
      this.addressService.deleteAddress(addressId).subscribe((result: any) => {
        // update the address list
        this.addressResults.next(
          this.addressResults.value.filter(
            (address) => address.addressId !== addressId
          )
        );
      })
    );
  }
}
