<section>
  <div class="container">
    <div *ngIf="isLoading$.value">
      <div class="row mt-5">
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="col-4 col-sm-4 col-md-4 m-1">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                'height.px': 60,
                'background-color': '#f3f3f3'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <ngx-skeleton-loader
              [theme]="{
                'height.px': 60,
                'background-color': '#f3f3f3'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <ngx-skeleton-loader
              [theme]="{
                'height.px': 60,
                'background-color': '#f3f3f3'
              }"
            ></ngx-skeleton-loader>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <ngx-skeleton-loader
              [theme]="{
                'height.px': 60,
                'background-color': '#f3f3f3'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
        <div
          class="col-sm-12 col-md-6 d-flex flex-column justify-content-between mb-3"
        >
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 90,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <!--Account-->
    <div *ngIf="!isLoading$.value" class="account pt-5 pb-5">
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3">
          <form
            [formGroup]="accountForm"
            class="rounded bg-theme-surface"
            (ngSubmit)="submit()"
          >
            <h1 class="font-title font-bold">My details</h1>

            <div class="form-group mt-4">
              <label class="form-label ea-label required" for="firstName">
                Email
              </label>
              <input
                type="text"
                style="background-color: beige"
                class="ea-input w-100 focus-ring"
                id="email"
                aria-describedby=""
                placeholder="email"
                formControlName="email"
                alt="email input"
              />
              <div class="row">
                <div class="col-9"></div>
                <div class="col-3">
                  <a
                    routerLink="/reset-email"
                    style="color: brown; text-align: right"
                    >Change Email</a
                  >
                </div>
              </div>
            </div>
            <div class="form-group mt-4">
              <label for="phone" class="ea-label required">Phone number</label>
              <br />
              <ngx-intl-tel-input
                class="ea-input w-100 focus-ring"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.UnitedKingdom"
                [maxLength]="15"
                [phoneValidation]="true"
                [separateDialCode]="separateDialCode"
                [numberFormat]="PhoneNumberFormat.National"
                name="phone"
                formControlName="phone"
              >
              </ngx-intl-tel-input>
            </div>

            <div class="form-group mt-4">
              <label class="form-label ea-label required" for="firstName"
                >First name</label
              >
              <input
                type="text"
                class="ea-input w-100 focus-ring"
                id="firstName"
                aria-describedby=""
                placeholder="First name"
                formControlName="firstName"
                alt="first name input"
              />
            </div>

            <div class="form-group mt-4">
              <label class="form-label ea-label required" for="lastname"
                >Last name</label
              >

              <div class="input-group">
                <input
                  formControlName="lastName"
                  placeholder="Last name"
                  class="ea-input form-control focus-ring"
                  id="lastname"
                  alt="last name input"
                />
              </div>
              <br />
              <input
                type="checkbox"
                class="me-2 form-check-input focus-ring"
                id="allowEmail"
                formControlName="allowEmail"
                alt="newsletter checkbox"
              />
              <label for="allowEmail" class="form-label">
                Please send me emails with news, updates and offers
              </label>
              <button
                type="submit"
                value="CREATE ACCOUNT"
                class="ea-button ea-button-primary font-title col-sm-12 col-md-12 col-lg-12 col-xl-12 w-100 focus-ring"
                [disabled]="
                  (accountForm.invalid &&
                    accountForm.controls['phone'].status == 'INVALID' &&
                    !accountForm.controls['phone'].value) ||
                  (isLoading$ | async) ||
                  (isButtonsDisabled$ | async)
                "
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
        <div
          class="col-sm-12 col-md-6 d-flex flex-column justify-content-between mb-3"
        >
          <div class="security col-12 row mx-auto p-3 rounded">
            <button
              type="button"
              [disabled]="(isLoading$ | async) || (isButtonsDisabled$ | async)"
              class="ea-button mt-3 ea-button-primary font-title col-sm-12 col-md-12 col-lg-12 col-xl-12 w-100 focus-ring"
              (click)="ChangePassword()"
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
