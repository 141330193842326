<footer class="pt-5">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-3 col-lg-4">
        <span class="pb-3 font-bold">About EnableAll</span>
        <br /><br />
        <ul class="p-0">
          <li>
            <a [routerLink]="['our-story']" class="focus-outline">Our story</a>
          </li>
          <li>
            <a [routerLink]="['our-values']" class="focus-outline">
              Our values
            </a>
          </li>
          <li>
            <a [routerLink]="['accessibility-statement']" class="focus-outline">
              Accessibility statement
            </a>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4">
        <span class="pb-3 font-bold">We are here to help</span>
        <br /><br />
        <ul class="p-0">
          <li>
            <a [routerLink]="['delivery-policy']" class="focus-outline"
              >Delivery</a
            >
          </li>

          <li>
            <a [routerLink]="['refunds-and-exchanges']" class="focus-outline"
              >Refunds & exchanges</a
            >
          </li>
          <li>
            <a [routerLink]="['contact-us']" class="focus-outline"
              >Contact us</a
            >
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-4 mb-5" *ngIf="!this.isSubmit">
        <span class="pb-3 font-bold">Stay in touch</span>
        <br /><br />
        <form
          [formGroup]="newsLetterForm"
          data-synerise="newsletter"
          (submit)="submit($event)"
          class="ea-form bg-none border-none p-0 mb-5"
        >
          <p class="pb-4 text-theme-on-surface">
            Be the first to know about EnableAll’s news, promotions, sales and
            more. We promise it will be worthwhile and we won’t flood your
            inbox.
          </p>
          <div class="form-group">
            <label class="ea-label required" for="fname">First name</label>
            <input
              type="text"
              class="ea-input w-100"
              id="fname"
              placeholder="First name"
              formControlName="firstname"
              alt="first name input"
            />
          </div>
          <div class="form-group mt-4">
            <label class="ea-label required" for="lname">Last name</label>
            <input
              type="text"
              class="ea-input w-100"
              id="lname"
              placeholder="Last name"
              formControlName="lastname"
              alt="last name input"
            />
          </div>
          <div class="form-group mt-4">
            <label class="ea-label required" for="email">Email address</label>
            <input
              type="email"
              class="ea-input w-100"
              id="email"
              formControlName="email"
              placeholder="Enter email"
              alt="email input"
            />
          </div>

          <div class="newsLetterCheckbox form-group form-check mt-4 mb-4">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="newsletterAgreement"
              value=""
              id="checkbox"
              checked
              alt="newsletter agreement checkboxes"
            />
            <label class="form-check-label" for="checkbox">
              I agree to receive communications from EnableAll and consent to
              the storage of my data according to our
              privacy policy
            </label>
          </div>
          <input
            [disabled]="!newsLetterForm.valid"
            type="submit"
            title="Subscribe"
            class="ea-button ea-button-primary w-full"
            value="Subscribe"
            alt="subscribe"
          />
        </form>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-4 mb-5" *ngIf="this.isSubmit">
        <h2 class="font-bold mb-4">Thank You</h2>
        <p class="mb-4">A member of our team will be in touch shortly.</p>
      </div>
    </div>
  </div>
  <div class="copyright bg-black p-5">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-12 col-md-5 col-lg-4">
          <p class="text-white">
            &copy; 2023 EnableAll Limited -All rights reserved
          </p>
        </div>
        <div class="col-sm-12 col-md-8 col-lg-7">
          <ul class="list-inline p-0 m-0 float-left">
            <li class="list-inline-item">
              <a
                [routerLink]="['privacy-policy']"
                class="text-white focus-outline"
                >Privacy policy</a
              >
            </li>
            <li class="list-inline-item">
              <a
                [routerLink]="['web-usage-policy']"
                class="text-white focus-outline"
                >Web usage policy</a
              >
            </li>
            <li class="list-inline-item">
              <a
                [routerLink]="['customer-terms']"
                class="text-white focus-outline"
                >Customer terms</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
