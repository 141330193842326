import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { RouterLink } from '@angular/router';
import { AuthLinksComponent } from 'src/app/modules/auth/auth-links/auth-links.component';
import { BaseComponent } from '../base.component';
import { NavCategoryItem } from '@ea/models';
import environment from 'src/environments/environment';
import { map, Observable } from 'rxjs';
import { cartSelectors } from '@ea/cart';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ea-masthead',
  standalone: true,
  imports: [CommonModule, SearchBarComponent, RouterLink, AuthLinksComponent],
  templateUrl: './masthead.component.html',
  styleUrls: ['./masthead.component.scss'],
})
export class MastheadComponent extends BaseComponent implements OnInit {
  @Input() categories?: NavCategoryItem[];
  readMode: boolean = false;
  cartProductsCount$ = new Observable<string | number>();

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.cartProductsCount$ = this.store
      .select(cartSelectors.getItemsInTheCart)
      .pipe(map((count) => (count > 0 ? count : '')));
  }

  public get logoTitle() {
    const title = 'EnableAll Home';
    if (environment.production) {
      return `${title} ${environment.stripe.country}`;
    }

    return title;
  }

  openA11yWidget() {
    let element = document
      .getElementById('a11y-access-tool-general-button')
      ?.click();
  }
}
