import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { CartFacade } from '../../+state';
import { Observable } from 'rxjs';
import { CartResponse } from '../../models';
import { PaymentFormComponent } from '../../components/payment-form';
import { AuthFacade } from '@ea/auth';
import { IUser } from '@ea/models';

@Component({
  selector: 'ea-payment',
  standalone: true,
  imports: [CommonModule, RouterLink, PaymentFormComponent],
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent {
  cart$?: Observable<CartResponse>;
  user$?: Observable<IUser>;

  ngOnInit(): void {
    this.cart$ = this.facade.cart$;
    this.user$ = this.authFacade.user$;
  }

  constructor(private facade: CartFacade, private authFacade: AuthFacade) {}
}
