import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartPageComponent, CheckoutPageComponent } from './pages';

const routes: Routes = [
  { path: 'cart', title: 'Basket | EnableAll', component: CartPageComponent },
  {
    path: 'checkout',
    title: 'Checkout | EnableAll',
    component: CheckoutPageComponent,
  },
  {
    path: 'payment',
    title: 'Payment | EnableAll',
    loadComponent: () =>
      import('../cart/pages/payment/payment-page.component').then(
        (x) => x.PaymentPageComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CartRoutingModule {}
