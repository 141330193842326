import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import environment from 'src/environments/environment';
import { Price } from 'src/app/models/productDetails.model';

@Component({
  selector: 'ea-item-price',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './item-price.component.html',
  styleUrls: ['./item-price.component.scss'],
})
export class ItemPriceComponent {
  @Input() price?: Price | null;
  @Input() priceRange?: any | null;
  currency = environment.stripe.country == 'GB' ? '£' : '$';
}
