<section>
  <div class="container">
    <div class="header">
      <h1 class="font-bold font-title">Delivery</h1>
      <h2 class="font-bold">Your Selected Delivery Address</h2>
    </div>
    <div class="row">
      <div>
        <ea-address *ngIf="selectedAddress" [address]="selectedAddress">
        </ea-address>

        <h2 class="font-bold">Change Delivery Address</h2>

        <select
          class="form-select bg-theme-surface focus-outline text-theme-on-surface"
          [(ngModel)]="selectedAddress"
          (ngModelChange)="onAddressSelected($event)"
          aria-label="Address options"
        >
          <option value="" disabled>Select an address</option>
          <option value="new address" >
              Add new Address
      
          </option>
          <option
            *ngFor="let address of availableAddresses"
            [ngValue]="address" [disabled]="checkCountryAvailable(address)"
          >
          <span *ngIf=" address.additionalAttributes.nickName"
              class="bg-theme-surface focus-outline text-theme-on-surface"
              >{{ address.additionalAttributes.nickName }} &nbsp; &nbsp;&nbsp;&nbsp;</span
            >
            <span
              class="bg-theme-surface focus-outline text-theme-on-surface"
              >{{ address.addressLine1 }}</span
            >&nbsp;
          </option>
        </select>
      </div>
    </div>
  </div>
</section>
