<section>
  <div class="container">
    <!--My Addresses-->
    <div class="my-addresses pt-3 pb-5">
      <div class="row">
        <h1 class="font-bold">My Addresses</h1>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 pe-5">
          <div class="default-address pt-3">
            <h2 class="font-bold">My Default Address</h2>
            <div class="mb-4"></div>
            <!--Address Template-->

            <div *ngIf="loader" style="padding: 0px; margin-bottom: 0px">
              <ngx-skeleton-loader
                count="1"
                [theme]="{
                  'height.px': 150,
                  'background-color': '#f3f3f3'
                }"
              ></ngx-skeleton-loader>
            </div>

            <ng-container *ngIf="!defaultAddress && !loader">
              <p>You have no default address</p>
            </ng-container>

            <ng-container *ngIf="defaultAddress">
              <ea-address [address]="defaultAddress">
                <div class="btn-group">
                  <a
                    [routerLink]="['edit-address/', defaultAddress.addressId]"
                    class="ea-button ea-button-stroked text-uppercase w-100 p-2 focus-ring font-bold border-style"
                  >
                    Edit
                  </a>
                  <button
                    (click)="toggleDefault(defaultAddress, false)"
                    class="ea-button ea-button-stroked text-uppercase w-100 p-2 focus-ring font-bold border-style"
                  >
                    <span class="text-red">Delete</span>
                  </button>
                </div>
              </ea-address>
            </ng-container>
          </div>
        </div>

        <!--Other Addresses-->
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 ps-5 font-title">
          <div class="other-addresses pt-3">
            <h2 class="font-bold">My Other Address</h2>
            <div>
              <div class="mb-4"></div>

              <div *ngIf="loader" style="padding: 0px; margin-bottom: 0px">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{
                    'height.px': 150,
                    'background-color': '#f3f3f3'
                  }"
                ></ngx-skeleton-loader>
              </div>

              <ng-container *ngIf="!filteredAddressList.length && !loader">
                <p>You have no other addresses</p>
              </ng-container>

              <div *ngFor="let addressResult of filteredAddressList">
                <ea-address [address]="addressResult">
                  <div class="btn-group">
                    <button
                      (click)="toggleDefault(addressResult, true)"
                      class="ea-button ea-button-primary font-title text-uppercase  focus-ring font-bold"
                      [disabled]="isButtonsDisabled$ | async"
                    >
                      Set as default
                    </button>
                    <a
                      [routerLink]="['edit-address/', addressResult.addressId]"
                      class="ea-button ea-button-stroked font-title text-uppercase w-100 focus-ring font-bold border-style"
                    >
                      Edit
                    </a>
                    <button
                      (click)="deleteAddress(addressResult.addressId)"
                      class="ea-button ea-button-stroked font-title text-uppercase w-100 focus-ring font-bold border-style"
                    >
                      <span class="text-red">Delete</span>
                    </button>
                  </div>
                </ea-address>
              </div>
            </div>
          </div>
          <a
            class="ea-button ea-button-primary font-title text-uppercase w-100 focus-ring mt-3"
            [routerLink]="'new-address'" role=button
          >
            Add new address
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
