<ng-container *ngIf="cart$ | async as cart">
  <table class="w-100">
    <!-- <caption class="sr-only">
      Totals
    </caption> -->
    <tbody class="leading-relaxed">
      <tr>
        <th class="text-left py-3" scope="row">
          <h2>Subtotal</h2>
        </th>
        <td class="text-right">
          <h2>{{ total$ | async | currency: cart.currency }}</h2>
        </td>
      </tr>
      <tr *ngIf="cart.totalDiscount +  this.savetotalvalue  !== 0" class="savings">
        <th class="text-left py-3" scope="row">
          <h2>Your saving</h2>
        </th>
        <td class="text-right">
          -{{ cart.totalDiscount + this.savetotalvalue  | currency: cart.currency }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
