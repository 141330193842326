import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'ea-form-field',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  @Input() label = '';
  @Input() form?: FormGroup | null;
  @Input() name = 'input';
  @Input() alt = 'input';

  public get control() {
    return this.form?.controls[this.name] as FormControl;
  }

  public get isRequired() {
    return this.control.hasValidator(Validators.required);
  }
}
