import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BaseComponent } from '@ea/components';
import { IOrder } from '@ea/models';
import { OrderStatus } from 'src/app/models/order-status.enum';
import { FilterImagePipe } from 'src/app/modules/products/pipes';
import { SplitPipe } from 'src/app/modules/products/pipes/split.pipe';
import { OrderService } from 'src/app/services/shared/orders';

@Component({
  selector: 'ea-order-details-page',
  standalone: true,
  imports: [CommonModule, NgIf, RouterLink, SplitPipe, FilterImagePipe],
  templateUrl: './order-details-page.component.html',
  styleUrls: ['./order-details-page.component.scss'],
})
export class OrderDetailsPageComponent extends BaseComponent implements OnInit {
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute
  ) {
    super();
  }
  customId?: string;
  orders?: IOrder[];
  orderId!: string;
  totalItems: number = 0;
  calculatedCurrency!: number;
  orderStatus = OrderStatus;

  ngOnInit(): void {
    this.orderId = this.route.snapshot.params['orderId'];

    this.subscriptions.push(
      this.orderService.getOrderById(this.orderId).subscribe((results: any) => {
        this.orders = [results];
        let totalSum = 0;

        results.items?.forEach((item: any) => {
          totalSum += item.orderedQuantity;
        });

        this.totalItems = totalSum; // Assign the total sum to this.totalItems of type number

        // Calculate the calculatedCurrency based on the totalSum
        this.calculatedCurrency = totalSum * 1.05;
      })
    );
  }
}
