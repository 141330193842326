import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IProductSearchResults, AlgoliaSearchQuery, IProductSearchResult, ProductLike } from '@ea/models';
import { FilterImagePipe, ProductGridComponent, ProductsComponent } from '@ea/products';
import { Observable } from 'rxjs';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';

import { ProductLikeService } from 'src/app/services/shared/ProductLiks/productLikes.service';
import environment from 'src/environments/environment';

@Component({
  selector: 'ea-account-page',
  standalone: true,
  imports: [CommonModule, RouterLink, NgFor, NgIf,FilterImagePipe,
    ProductGridComponent,
    PaginationComponent,
    ProductsComponent,
    RouterLink,
    MatIconModule,],
  templateUrl: './productLikes-page.component.html',
  styleUrls: ['./productliks-page.component.scss'],
})
export class ProductliksPageComponent implements OnInit {
  products$!: Observable<IProductSearchResults | null>;

  products : ProductLike[] = [];
  skus : any [] =[];
  isBusy : boolean = false;
  constructor(private productLiksService : ProductLikeService) {
  }

  ngOnInit() {
    this.isBusy =true;
    this.productLiksService.getProductLiks().subscribe((x: any)=> {
      this.products = x; 
      this.products.forEach(product  => {
        this.skus.push({ sku : product.productSku , itemId : product.itemId, breadcrumbsString : product.breadcrumbsString})
      })
      this.isBusy = false});

  }



 

}
