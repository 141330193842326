import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormFieldComponent } from 'src/app/components/form-field/form-field.component';
import { CartService } from '../../services';
import {
  CartItem,
  CartResponse,
  createIntentRequest,
  FabricCheckoutRequest,
  WithIntentRequest,
} from '../../models/cart.model';
import { Stripe } from '@stripe/stripe-js';
import {
  BehaviorSubject,
  catchError,
  from,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { BaseComponent } from '@ea/components';
import { IUser } from '@ea/models';
import { CartFacade } from '../../+state';
import { RouterModule } from '@angular/router';
import { LocalStorageService } from '@ea/services';
import { MatIconModule } from '@angular/material/icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StripeLoaderService } from '../../services/stripe-loader-service';
import { DataLayerService } from 'src/app/services/shared/WindowReference/DataLayerService';
import environment from '../../../../../environments/environment';
import { CharityData, CharityOrder } from 'src/app/models/CharityData ';
import { CharityService } from 'src/app/services/shared/charityService/charity.service';
import { VertexService } from 'src/app/services/shared/Vertex/Vertex.Service';
import { StripeService } from 'src/app/services/shared/Stripe/stripe.Service';

@Component({
  selector: 'ea-payment-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormFieldComponent,
    FormsModule,
    MatIconModule,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentFormComponent extends BaseComponent {
  form: FormGroup;
  cards: any;
  selectedFullCard: any;
  thereAreCards: boolean = false;
  isLoaded: boolean = false;
  cart?: CartResponse;
  @Input() user?: IUser;
  isCardValid: boolean = false;
  ShowCardList: boolean = false;
  displayNewCard: boolean = false;
  error$ = new Subject<string>();
  isLoading$ = new BehaviorSubject<boolean>(false);
  isCartLoaded$ = new BehaviorSubject<boolean>(false);
  orderId?: string;
  saveCart: boolean = false;
  selectedCard: string = '';
  private stripe: Stripe | null = null;
  totalTax: number | undefined;
  totalAmount: number | undefined;
  selected: CharityData | undefined;
  options: CharityData[] = [];
  lineItemTax: any | [];
  taxRequest = {
    customer: {
      destination: {
        city: '',
        country: '',
        mainDivision: '',
        postalCode: '',
        streetAddress1: '',
        streetAddress2: '',
      },
    },
    lineItems: [{}],
    transactionId: 'this.cart.cartId',
    documentNumber: '',
  };
  charityText: string = 'With every purchase, EnableAll donates a portion of the proceeds to charity. Please select your preferred charity';

  constructor(
    private fb: FormBuilder,
    private service: CartService,
    private facade: CartFacade,
    private stripeLoaderService: StripeLoaderService,
    private storageService: LocalStorageService,
    private dataLayer: DataLayerService,
    private _change: ChangeDetectorRef,
    private charityService: CharityService,
    private vertexService: VertexService,
    private stripeService: StripeService
  ) {
    super();
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      addressLine1: [null, [Validators.required]],
      addressLine2: [null],
      city: [null, [Validators.required]],
      country: [null, [Validators.required]],
      county: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      cardNumber: [null, [Validators.required]],
      expiryMonth: [null, [Validators.required]],
      expiryYear: [null, [Validators.required]],
      cvc: [null, [Validators.required]],
    });
  }

  async ngOnInit() {
    this.initializeCharityOptions();
    this.initializeStripe();

    this.facade.cart$.subscribe((cart) => {
      this.cart = cart;
      this.patchAddress();
      if (this.cart?.items && this.cart.items.length > 0) {
        this.estimatedTax();
      }
    });

    this.stripeService
      .GetUserCards(this.storageService.getItem('customId'))
      .subscribe((cards) => {
        if (cards && cards.length > 0) {
          this.thereAreCards = true;
          this.cards = cards;
        }
        this.isLoaded = true;
        this.isCartLoaded$.next(true);
      });
  }

  private initializeCharityOptions() {
    this.charityService
      .getProfileDropDownCheckout(this.storageService.getItem('customId'))
      .subscribe((charities) => {
        if (charities) {
          this.options = charities;
          this.selected =
            charities.find((charity) => charity.isDefault) || charities[0];
        }
      });
  }

  private async initializeStripe() {
    try {
      this.stripe = await this.stripeLoaderService.getStripeInstance();
    } catch (error) {
      console.error('Failed to load stripe:', error);
    }
  }

  estimatedTax() {
    if (!this.cart?.items) {
      return;
    }

    const itemTaxes = this.cart.items.map((item) => ({
      quantity: { value: item.quantity },
      unitPrice: item.unitPrice.amount,
      lineItemId: item.itemId,
      vendorSKU: item.sku,
      inputTotalTax:
        environment.base === 'uk'
          ? (item.unitPrice.amount - item.unitPrice.amount / 1.2).toString()
          : '',
    }));

    this.taxRequest = {
      customer: {
        destination: {
          city: this.form.value['city'],
          country: this.form.value['country'],
          mainDivision: this.form.value['county'],
          postalCode: this.form.value['postalCode'],
          streetAddress1: this.form.value['addressLine1'],
          streetAddress2: this.form.value['addressLine2'] || ' ',
        },
      },
      lineItems: itemTaxes,
      transactionId: this.cart.cartId,
      documentNumber: '',
    };

    this.vertexService.calculateTax(this.taxRequest).subscribe((result) => {
      this.totalTax = result.totalTax;
      this.lineItemTax = result.lineItems;

      if (this.cart && environment.base == 'uk' && this.totalTax === 0) {
         var x = this.cart.items?.filter(x=> x.attributes.filter(y => y.name == "tax_code")[0].value != "uk0");
         var price = 0;
         x!.forEach(element => {
          price+= element.totalPrice.amount;
         });
        this.totalTax =price  - price / 1.2;
        this.totalAmount = this.cart.totalAmount;
      } else {
        this.totalAmount = this.cart!.totalAmount + this.totalTax;
      }

      this._change.markForCheck();
    });
  }

  postVertexTax(request: any, orderId: string) {
    if (this.totalTax !== 0) {
      request.documentNumber = orderId;
      this.vertexService.postTax(request).subscribe(() => {});
    }
  }

  calculateAmount() {
    let amount = 0;
    if (environment.base === 'uk') {
      amount = this.cart!.totalAmount;
    } else {
      amount = this.cart!.totalAmount + (this.totalTax ?? 0);
    }
    return amount;
  }
  prepareEvent() {
    const rEvent: any = {
      event: 'purchase',
      ecommerce: {
        value: this.calculateAmount(),
        currency: this.cart!.currency,
        shipping: 0,
        tax: this.getSumTaxes(),
        affiliation: 'none',
        items: this.getItemForEvent(),
      },
    };
    return rEvent;
  }
  calculateItemTaxes() {
    const itemTaxes = this.cart!.items!.map((x) => {
      let taxAmount = 0;

      if (environment.base === 'uk') {
        taxAmount = 0;
      } else {
        taxAmount = parseFloat(
          this.lineItemTax.find(
            (y: { lineItemId: string }) => y.lineItemId === 'EALL-' + x.itemId
          )?.totalTax || 0
        );
      }

      return {
        lineItemId: x.lineItemId,
        amount: taxAmount,
        type: 'vat',
      };
    });
    return itemTaxes;
  }
  getSumTaxes() {
    const tax = this.calculateItemTaxes();
    return tax.reduce((sum, tax) => sum + tax.amount, 0);
  }
  getShippingTaxes() {
    const hash: { [key: string]: CartItem[] } = {};
    this.cart!.items!.forEach((x) => {
      const key = x.shipTo.shipToId;
      const existing = hash[key] ?? [];
      hash[key] = [...existing, x];
    });

    const shipToTaxes = Object.keys(hash).map((key) => ({
      shipToId: key,
      amount: 0,
      type: 'vat',
    }));
    return shipToTaxes;
  }
  makePayment() {
    if (
      !this.cart ||
      !this.user ||
      !this.cart.items ||
      !this.cart.items.length ||
      !this.stripe
    ) {
      return;
    }
    const amount = this.calculateAmount();
    const cartId = this.cart.cartId;
    const email = this.user.emailAddress;
    const customerId = this.user.customId;
    const raw = this.form.getRawValue();

    if (this.selectedCard !== '') {
      const request: createIntentRequest = {
        payment_method: this.selectedCard,
        amount: amount.toString(),
        currency: this.cart.currency,
        fabric_cartid: this.cart.cartId,
        customId: this.storageService.getItem('customId'),
        saveCard: true,
      };

      this.isLoading$.next(true);
      this.error$.next('');
      const subscription = this.stripeService
        .createPaymentIntentOnly(request)
        .pipe(
          catchError(() => {
            this.error$.next(
              'Error creating payment intent. Please check your card information and try again!'
            );
            this.isLoading$.next(false);
            return of();
          }),
          switchMap((paymentIntent) => {
            if (paymentIntent.client_secret) {
              return from(
                (this.stripe as Stripe).confirmCardPayment(
                  paymentIntent.client_secret
                )
              );
            } else {
              this.error$.next(
                'Error creating payment intent. Please check your card information and try again!'
              );
              this.isLoading$.next(false);
              return of();
            }
          }),
          switchMap((result) => {
            if (result.error) {
              this.error$.next(
                result.error.message ||
                  'An unknown error occurred while processing your payment.'
              );
              this.isLoading$.next(false);
              return of();
            } else {
              if (result.paymentIntent.status === 'requires_capture') {
                const request = this.createFabricRequest(
                  result,
                  cartId,
                  email,
                  customerId
                );
                return this.service.attachPaymentToCart(request).pipe(
                  switchMap((result) => {
                    const rEvent = this.prepareEvent();
                    this.orderId = result.orderId;
                    rEvent.ecommerce.transaction_id = this.orderId;
                    this.sendEvent(rEvent);
                    return of(result.checkoutComplete);
                  }),
                  catchError(() => of(false))
                );
              }
              return of(false);
            }
          })
        )
        .subscribe((result) => {
          if (!result) {
            this.error$.next(
              'An unknown error occurred while processing your payment.'
            );
          } else {
            this.postVertexTax(this.taxRequest, this.orderId!);
            if (this.options.length > 0) {
              this.attachCharityToOrder(amount);
            }
            this.facade.find(customerId);
          }
          this.isLoading$.next(false);
        });

      this.subscriptions.push(subscription);
    } else {
      const request: WithIntentRequest = {
        cvc: raw.cvc,
        exp_month: raw.expiryMonth,
        exp_year: raw.expiryYear,
        number: raw.cardNumber,
        amount: amount,
        currency: this.cart.currency,
        fabric_cartid: this.cart.cartId,
        customId: this.storageService.getItem('customId'),
        saveCart: this.saveCart,
        userId: this.storageService.getItem('user').userId,
      };
      this.isLoading$.next(true);
      this.error$.next('');
      const subscription = this.stripeService
        .createPaymentIntent(request)
        .pipe(
          catchError(() => {
            this.error$.next(
              'Error creating payment intent. Please check your card information and try again!'
            );

            this.isLoading$.next(false);

            return of();
          }),
          switchMap((paymentIntent) => {
            if (paymentIntent.client_secret) {
              return from(
                (this.stripe as Stripe).confirmCardPayment(
                  paymentIntent.client_secret
                )
              );
            } else {
              this.error$.next(
                'Error creating payment intent. Please check your card information and try again!'
              );
              this.isLoading$.next(false);
              return of();
            }
          }),
          switchMap((result) => {
            if (result.error) {
              this.error$.next(
                result.error.message ||
                  'An unknown error occurred while processing your payment.'
              );
              this.isLoading$.next(false);

              return of();
            } else {
              if (result.paymentIntent.status === 'requires_capture') {
                const request = this.createFabricRequest(
                  result,
                  cartId,
                  email,
                  customerId
                );
                return this.service.attachPaymentToCart(request).pipe(
                  switchMap((result) => {
                    const rEvent = this.prepareEvent();
                    this.orderId = result.orderId;
                    rEvent.ecommerce.transaction_id = this.orderId;
                    this.sendEvent(rEvent);
                    return of(result.checkoutComplete);
                  }),
                  catchError(() => of(false))
                );
              }
              return of(false);
            }
          })
        )
        .subscribe((result) => {
          if (!result) {
            this.error$.next(
              'An unknown error occurred while processing your payment.'
            );
          } else {
            this.postVertexTax(this.taxRequest, this.orderId!);
            if (this.options.length > 0) {
              this.attachCharityToOrder(amount);
            }
            this.facade.find(customerId);
          }
          this.isLoading$.next(false);
        });

      this.subscriptions.push(subscription);
    }
  }

  attachCharityToOrder(amount: any) {
    const raw = this.form.getRawValue();
    const request: CharityOrder = {
      charityID: this.selected?.charityId!,
      orderID: this.orderId!,
      personName: raw.firstName + ' ' + raw.lastName,
      totalAmount: amount,
    };
    this.charityService.attachCharityToOrder(request).subscribe();
  }
  public createFabricRequest(
    result: any,
    cartId: string,
    email: string,
    customerId: string
  ) {
    const raw = this.form.getRawValue();
    const itemTaxes = this.calculateItemTaxes();
    const shipToTaxes = this.getShippingTaxes();
    const name = { first: raw.firstName, last: raw.lastName };
    const phone = { number: raw.phoneNumber, kind: 'MOBILE' };

    const request: FabricCheckoutRequest = {
      cartId: cartId,
      customerEmail: email,
      customerName: name,
      payment_method: result.paymentIntent.payment_method!.toString(),
      stripId: customerId,
      paymentDetails: [
        {
          connectorName: 'stripe',
          paymentMethod: 'CARD',
          paymentToken: result.paymentIntent.id,
          amount: result.paymentIntent.amount / 100,
          currency: result.paymentIntent.currency,
          billToAddress: {
            name: name,
            phone: phone,
            email: email,
            street1: raw.addressLine1,
            city: raw.city,
            state: raw.county,
            country: (raw.country == 'GBP' || raw.country == 'GB') ? 'GB' : 'USA',
            zipCode: raw.postalCode,
            customerId: customerId,
          },
        },
      ],
      customerPhoneNumber: phone,
      estimatedTax: { itemsTaxes: itemTaxes, shipToTaxes: shipToTaxes },
    };
    return request;
  }

  sendEvent(ev: any) {
    this.dataLayer.SendEvent(ev);
  }

  getItemForEvent() {
    return this.cart!.items!.map((item) => {
      let itemObject = {
        item_name: item.title,
        item_id: item.sku,
        item_price: item.totalPrice.amount,
        item_brand: item.attributes.find((x) => x.name == 'vendor_name')?.value,
        quantity: item.quantity,
        item_variant: item.attributes.find((x) => x.name == 'display_name')
          ?.value,
      };

      const breadcrumbsString = item.extra.breadcrumbsString.value;
      const breadcrumbs = breadcrumbsString?.split('>');
      for (let i = 0; i < breadcrumbs?.length; i++) {
        const cat = breadcrumbs[i];
        const str = 'item.item_category' + (i + 1) + '=' + '"' + cat + '";';
        eval(str);
      }
      return itemObject;
    });
  }

  patchAddress() {
    if (this.cart?.items) {
      const address = this.cart.items[0]?.shipTo.address;

      if (address) {
        this.form.patchValue({
          city: address.city,
          country: address.country,
          firstName: address.name.first,
          lastName: address.name.last,
          postalCode: address.zipCode,
          county: address.state,
          addressLine1: address.street1,
          addressLine2: address.street2,
          phoneNumber: address.phone.number,
        });
      }
    }
  }

  show() {
    this.displayNewCard = !this.displayNewCard;
    this.selectedCard = '';
    this.form.controls['cardNumber'].setValue(null);
    this.form.controls['expiryMonth'].setValue(null);
    this.form.controls['expiryYear'].setValue(null);
    this.form.controls['cvc'].setValue(null);
  }

  showList() {
    this.ShowCardList = !this.ShowCardList;
  }

  onOptionsChanged(event: CharityData) {
    this.selected = event;
  }

  selectedCardId(card: any) {
    this.selectedCard = card.paymentMethodId;
    this.selectedFullCard = card;
    this.isCardValid = false;
    this.form.controls['cardNumber'].setValue(' ');
    this.form.controls['expiryMonth'].setValue(' ');
    this.form.controls['expiryYear'].setValue(' ');
    this.form.controls['cvc'].setValue(' ');
    this.showList();
  }

  
}
