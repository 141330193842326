<section class="pb-5">
  <div class="container" *ngIf="user$ | async as user">
    <div class="header pt-5">
      <h1 class="font-bold">My Orders</h1>
    </div>
    <div *ngIf="(!orders || orders.length == 0) && !loader">
      <p class="text-theme-on-background">You have no order yet</p>
    </div>
    <div *ngIf="loader" style="padding: 0px; margin-bottom: 0px">
      <ngx-skeleton-loader
        count="1"
        [theme]="{
          'height.px': 150,
          'background-color': '#f3f3f3'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="col-lg-9">
      <div *ngFor="let order of orders">

        <div class="mt-5"></div>

        <div
          class="order-header p-2 rounded-top border-top-darker-1px border-right-darker-1px border-bottom-darker-1px border-left-darker-1px ps-3 pe-3 bg-theme-surface text-theme-on-surface"
        >
        <div class="row">
          <div class="col-lg-6">
          <h2 style="font-size: 20px;" class="mt-3">ORDER: #    <span class="text-theme-on-surface">{{ order.orderId }}</span></h2>
          </div>
          <div class="col-lg-6">
          <div class="float-right">
            <div class="order-number">
              <div *ngIf="order.items">
                <a
                  [routerLink]="['order-details/', order.orderId]"
                  class="ea-button ea-button-primary float-right mt-2 mb-2 font-title"
                  >View Order Details</a
                >
              </div>
            </div>
          </div>
          </div>
          <hr>
        </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 text-left">
              <p class="text-theme-on-surface">ORDER PLACED:</p>
              <div class="mt-3"></div>
              <p class="text-theme-on-surface">
                {{ order.createdAt | date : "d MMMM y" }}
              </p>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-4 text-left">
              <div *ngIf="order.shipInfo && order.shipInfo.length > 0">
                <div class="row ps-3 mobile-shipping justify-content-start">
                  <p class="text-theme-on-surface">SHIP TO:</p>
                  <div class="p-0 mt-3 text-left font-title">
                    {{ order.shipInfo[0].shipToAddress.name.first }}
                    {{ order.shipInfo[0].shipToAddress.name.last }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-4 text-left">
              <div *ngIf="order.shipInfo && order.shipInfo.length > 0">
                <div class="row ps-3 mobile-shipping justify-content-start">
                  <p class="text-theme-on-surface">STATUS:</p>
                  <div class="p-0 mt-3 text-left font-title">
                    {{ order.statusDescription }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card rounded-bottom border-right-gray-2px border-bottom-gray-2px border-left-gray-2px border-right-darker-1px border-bottom-darker-1px border-left-darker-1px mb-5 bg-theme-surface text-theme-on-surface"
        >
          <div *ngFor="let item of order.items">
            <div class="row mb-3">
              <div class="col-md-2 col-lg-2">
                <img
                  [src]="item.product_image"
                  alt="Product Image"
                />
              </div>
              <div class="col-md-10 col-lg-10 pt-4">
                <h3 class="text-theme-on-surface">{{ item.title }}</h3>
                <p class="text-theme-on-surface">
                  Quantity: {{ item.orderedQuantity }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
