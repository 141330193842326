<div class="toast show" role="alert" aria-live="polite">
  <div class="p-4 bg-theme-surface text-theme-on-surface shadow-lg border">
    <div class="flex flex-row gap-4 items-center">
      <ng-container *ngIf="message">
        <p class="text-base text-theme-on-surface toast-msg">{{ message }}</p>
      </ng-container>

      <div>
        <button
          class="ea-button ea-button-primary"
          (click)="dispose.emit()"
          title="Dismiss"
        >
          <span class="material-icons">close</span>
        </button>
      </div>
    </div>
  </div>
</div>
