import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CartFacade } from '../../+state';
import { CartResponse } from '../../models';
import { CommonModule } from '@angular/common';
import { CartTracking, CartProduct } from '@ea/products';

declare const SR: any;

@Component({
  selector: 'ea-cart-totals',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cart-totals.component.html',
  styleUrls: ['./cart-totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartTotalsComponent implements OnInit {
  cart$?: Observable<CartResponse>;
  // estimatedItemsTaxes$?: Observable<number>;
  // estimatedShippingCost$?: Observable<number>;
  // estimatedShippingTaxes$?: Observable<number>;
  total$?: Observable<number>;
  totalSave$?: Observable<number>;
  savetotalvalue: number=0;  
  constructor(private cart: CartFacade) {}

  ngOnInit(): void {
    this.cart$ = this.cart.cart$;
    // this.estimatedItemsTaxes$ = this.cart.estimatedItemsTaxes$;
    // this.estimatedShippingCost$ = this.cart.estimatedShippingCost$;
    // this.estimatedShippingTaxes$ = this.cart.estimatedShippingTaxes$;
    this.trackSyneriseCart();
    this.totalSave$ = this.cart$.pipe(
      map((cart) => {
        return (
          cart.items?.reduce((acc, item) => {
            return (
              acc +
             ( (item.extra.orginalPrice.price * item.quantity) - item.totalPrice.amount)
            );
          }, 0) ?? 0
        );
      })
    );
  this.totalSave$.subscribe(x => this.savetotalvalue = x);
    this.total$ = this.cart$.pipe(
      map((cart) => {
        return (
          cart.items?.reduce((acc, item) => {
            return (
              acc +
              item.totalPrice.amount +
              item.extra.customValues.price * item.quantity
            );
          }, 0) ?? 0
        );
      })
    );
  }

  trackSyneriseCart(){
    this.cart$?.subscribe((userCart: CartResponse) => {      
      const cartTracking: CartTracking = {} as CartTracking;
      cartTracking.products = [];
      userCart.items?.forEach((cartItem) => {
        let brandName = "";
        const brandNameObj = cartItem.attributes.filter(x => x.name == "brand")[0];
        if(brandNameObj){
          brandName = brandNameObj.value;
        }

        const productCategory = cartItem.extra?.breadcrumbsString?.value;

        const cartProduct: CartProduct = {
          sku: cartItem.sku,
          quantity: cartItem.quantity,
          brand: brandName,
          category: productCategory
        }
        cartTracking.products.push(cartProduct);
      });

      cartTracking.totalAmount = userCart.totalAmount;
      cartTracking.totalQuantity = userCart.totalItems;
      cartTracking.itemIds = cartTracking.products.map(({ sku } : {sku:string}) => sku);
      
      //Synerise tracking for abandoned Carts
      SR.event.trackCustomEvent('cart.status', cartTracking, 'CartStatus');
    });
  }
}
